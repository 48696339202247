import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Input from "components/Input/Input"
import { ConfigContext } from "contexts/ConfigContext"
import ErrorMessageText from "components/ErrorMessageText/ErrorMessageText"
import { styles } from "./SingleFieldQuestionForm.styles"

export default function SingleFieldQuestionForm({
  head,
  paragraph,
  fieldName,
  fieldPlaceholder,
  handleSubmit,
  isSubmitting,
  buttonText,
  schema,
  showResponseView,
  responseViewHead,
  responseViewParagraph,
  errorMessage,
}) {
  const { config } = useContext(ConfigContext)

  return (
    <div css={styles({ config })}>
      {!showResponseView ? (
        <>
          <h1 className="head">{head}</h1>
          <p className="paragraph">{paragraph}</p>
          <div className="form-container">
            <Formik
              initialValues={{ [fieldName]: "" }}
              onSubmit={handleSubmit}
              validationSchema={schema}
              validateOnChange
            >
              <Form>
                <div className="field-container">
                  <Field
                    name={fieldName}
                    type="text"
                    placeholder={fieldPlaceholder}
                    autoComplete="off"
                    component={Input}
                  />
                  <ErrorMessage
                    name={fieldName}
                    className="field-error-message-container"
                    component={ErrorMessageText}
                  />
                </div>
                <button
                  className="regular-button submit-button-container"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <img
                      className="submit-button-animation"
                      src="/animations/processing.gif"
                      alt="Submit in progress animation"
                    />
                  ) : (
                    <span>{buttonText}</span>
                  )}
                </button>
              </Form>
            </Formik>
          </div>
          <div className="error-message">{errorMessage}</div>
        </>
      ) : (
        <>
          <h1 className="response-head">{responseViewHead}</h1>
          <p className="response-paragraph">{responseViewParagraph}</p>
        </>
      )}
    </div>
  )
}

SingleFieldQuestionForm.defaultProps = {
  errorMessage: "",
}

SingleFieldQuestionForm.propTypes = {
  head: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldPlaceholder: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  schema: PropTypes.any.isRequired,
  showResponseView: PropTypes.bool.isRequired,
  responseViewHead: PropTypes.string.isRequired,
  responseViewParagraph: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
}
