import { css } from "@emotion/react"

export const styles = ({ isFocused, isError }) => css`
  padding: 12px 20px;
  border: 1px solid;
  border-radius: 10px;
  border-color: ${isError ? "#F24B4B" : isFocused ? "#cbe1ef" : "#e5e7f0"};
  background-color: ${isFocused ? "transparent" : "#fbfcff"};

  &:hover {
    border-color: ${isError ? "#F24B4B" : isFocused ? "#cbe1ef" : "#e5e7f0"};
  }
`
