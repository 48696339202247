import { css } from "@emotion/react"

export const styles = (color, hoverColor) => css`
  border: none;
  background-color: transparent;
  cursor: pointer;

  .back-button-icon {
    transform: rotate(180deg);
    width: 16px;
  }

  .back-button-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.5px;
    color: ${ !!color ? color : '#aaacb4'};
    margin-left: 10px;
    display: inline-block;
  }

  &:hover {
    .back-button-text {
      color: ${ !!hoverColor ? hoverColor : '#ffffff'};
    }

    svg path {
      fill: ${ !!hoverColor ? hoverColor : '#ffffff'};
    }
  }
`
