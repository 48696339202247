import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ config }) => css`

  .main-container {
    padding: 30px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height: 80vh;
  }

  @media (max-width: 768px) {

    .main-container {
      flex-direction:column;
    }
  }
  
  .back-button-container {

  }

  .title{
    font-size: 40pt;
    font-weight: 900;
    color: ${config.primaryColor};
    text-align: center;
    margin-bottom:20px;
    @media (max-width: 768px) {
      font-size:24px;
    }
  }

  .subtitle{
    font-size: 13pt;
    line-height:30px;
    text-align: center;
    @media (max-width: 768px) {
      font-size:14px;
    }
  }

  .form-container{
    display: flex;
    margin-top:30px;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction:column;
    }
    input{
        border: 1px solid #cccccc;
        border-radius: 10px;
        background-color: #fafafa;
        border-color: #DDDDDD;
        width: 70%;
        height: 60px;
        outline: none;
        padding-left: 15px;
        font-size: 14pt;
        margin-right: 10px;
        @media (max-width: 768px) {
          width:100%;
          margin-bottom:15px;
          font-size:14px;
        }
      }

      a{
        background-color: ${config.primaryColor};
        width: 30%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        color: #FFFFFF;
        font-weight: 600;
        @media (max-width: 768px) {
          width:100%;
          font-size:14px
        } 

        img{
          width:50%;
        }

      }

      .disabled{
        background-color: #DDDDDD;
      }
  }

  .error{
    margin-top:20px;
    color: red;
    text-align: center;
    font-weight:600;
    height:20px;
  }

  .email-sent-img{
    width:150px;
    height: 150px;
    opacity: 0.9;
    margin-bottom: 20px;
  }


 

`
