import PropTypes from "prop-types"
import { styles } from "./BackButton.styles"

export default function BackButton({ onClick, buttonText, color, hoverColor }) {
  return (
    <button
      css={styles(color, hoverColor)}
      type="button"
      className="back-button"
      onClick={onClick}
    >
      <svg
        className="back-button-icon"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.47034e-07 8L12.5152 8L7.54183 12.8104L8.77046 14L16 7L8.77046 0L7.54183 1.18962L12.5152 6.00505L0 6.00505L4.47034e-07 8Z"
          fill={ !!color ? color : "#AAACB4"}
        />
      </svg>
      <span className="back-button-text">{buttonText}</span>
    </button>
  )
}

BackButton.defaultProps = {
  buttonText: "Back",
  onClick: () => {},
}

BackButton.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
}
