
import { Link } from "react-router-dom"
import ContactSuccessImage from "assets/images/contact-success.png"
import useUrl from "hooks/useUrl"
import { styles } from "./ContactSuccess.styles"

export default function ContactSuccess() {
  const { exactUrl } = useUrl()

  return (
    <div css={styles()}>
      <div className="content">
        <div className="contact-success-image-container">
          <img className="contact-success-image" src={ContactSuccessImage} alt="Checkout Success" />
        </div>
        <h1 className="title">
          Thank you for your message!
        </h1>
        <p className="paragraph">We typically reply in 1-3 business days</p>
        <div className="buttons-container">
          <div className="button-container">
            <Link to={`${exactUrl}/`} className="go-home-button action-button">
              Return Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
