import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ isSubmitButtonBusy }) => css`
  .head {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .error-message {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #f24b4b;
    margin-bottom: 20px;
  }

  .checkout-confirmation-item-container {
    margin-bottom: 20px;
  }

  .tax {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .tax-label,
  .tax-value {
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #898b93;
  }

  .total {
    display: flex;
    justify-content: space-between;
  }

  .total-label,
  .total-value {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .submit-button-container {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    margin-bottom-30px;

    .action-button {
      height: 55px;
      ${isSubmitButtonBusy
        ? `
          cursor: not-allowed;
          padding: 0;
          display: flex;
          justify-content: center;
        `
        : ""}

      &:hover {
        ${isSubmitButtonBusy ? "background-color: #111319;" : ""}
      }
    }
  }

  .mobile-widths {
    width: 100%;
  }

  .submit-button-animation {
    width: 55px;
  }

  .clickwrap-agreement-container,
  .bank-statement-agreement-container {
    margin-top: 20px;
    display: flex;
  }

  .clickwrap-agreement,
  .bank-statement-agreement {
    font-size: 12px;
    line-height: 120%;
    color: #898b93;
  }

  .bank-statement-preview {
    font-size: 12px;
    line-height: 120%;
    color: #898b93;
  }

  .clickwrap-link {
    color: blue;
    cursor: pointer;
  }

  .agreement-checkbox {
    margin-left: 10px;
    margin-right: 5px;
  }

  .agreement-checkbox input {
    width: 25px;
    height: 25px;
  }

  .action-button .pledge:hover {

  }

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    color: white;
    z-index: 9999;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
  
  .modalHeader {
    height: 50px;
    background: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin: 15px 20px;
  }
  
  .heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  
  .modalContent {
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
    border: 2px solid grey;
  }

  .modalContent::-webkit-scrollbar-track {
	  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	  border-radius: 5px;
	  background-color: #F5F5F5;
  }

  .modalContent::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  .modalContent::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }

  .modalActions {
    margin: 15px 20px;
  }
  
  .actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .cancelBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: white;
    background: red;
    transition: all 0.25s ease;
  }
  
  .cancelBtn:hover {
    box-shadow: none;
    transform: none;
  }

  .rodal-close {
    position: absolute;
    cursor: pointer;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
  }

  .rodal-close:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #999;
    border-radius: 100%;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .rodal-close:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #999;
    border-radius: 100%;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .rodal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    color: white;
    z-index: 9999;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    max-width: 60vw;
    min-width: 300px;
    min-height: 200px;
    max-height: 75vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 75vh !important;
  }

  .rodal-mask {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    z-index: 9998;
    top: 0;
    left: 0;
    position: fixed;
  }

  ${mediaQueries.tabletScreen} {
    .head {
      font-size: 36px;
      line-height: 110%;
      letter-spacing: -1px;
    }

    .price-summary {
      margin-top: 30px;
    }

    .rodal-dialog {
      margin-top: 100px;
      width: 50vw !important;
      height: 70vw !important;
    }

    .agreement-checkbox {
      margin-right: 5px;
    }

    .agreement-checkbox input {
      width: auto;
      height: auto;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    .submit-button-container {
      position: initial;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .mobile-widths {
      width: 100%;
      margin-left: 0;
    }

    .rodal-dialog {
      left: 128px;
      margin: auto;
      width: 50vw !important;
      height: 70vw !important;
    }
  }
`
