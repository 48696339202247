import { useContext } from "react"
import { ConfigContext } from "contexts/ConfigContext"

export default function ExpandPlusIcon() {
  const { config } = useContext(ConfigContext)

  return (
    <svg
      style={{ minWidth: '16px' }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 0H10V16H6V0Z" fill={config.primaryColor} />
      <path d="M16 6V10H0L1.74846e-07 6L16 6Z" fill={config.primaryColor} />
    </svg>
  )
}
