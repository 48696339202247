import { Fragment, useContext, useCallback, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import {
  useElements,
  useStripe,
  CardNumberElement,
} from "@stripe/react-stripe-js"
import { PurchaseContext } from "contexts/PurchaseContext"
import { ConfigContext } from "contexts/ConfigContext"
import VisaLogo from "components/VisaLogo/VisaLogo"
import MasterCardLogo from "components/MasterCardLogo/MasterCardLogo"
import { createPaymentIntent, finalizeFulfill } from "api/checkout"
import useUrl from "hooks/useUrl"
import dayjs from "utils/dayjs"
import CheckoutConfirmationItem from "./CheckoutConfirmationItem/CheckoutConfirmationItem"
import { styles } from "./CheckoutConfirmation.styles"
import { APP_VARIANTS } from "config"
import { createSubscription } from "api/subscriptions"
import Rodal from 'rodal'

const SECOND = 1000

export default function CheckoutConfirmation({
  ticketText,
  togglePurchaseMenu,
  goToStepByIndex,
  shown,
}) {
  const { config } = useContext(ConfigContext)

  const { myGetPricingTierId, 
          mySetPricingTierId, 
          myGetPersonalInformation, 
          creditCardType, 
          myGetTeamIndex, 
          myGetPledgePerWinId } = useContext(PurchaseContext);

  const { exactUrl } = useUrl()
  const origin = window.location.origin;

  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState("")
  const [clientSecret, setClientSecret] = useState("")
  const [paymentIntentId, setPaymentIntentId] = useState("")
  const [paymentToken, setPaymentToken] = useState("")
  const [orderReferenceNumber, setOrderReferenceNumber] = useState("")

  const [clickwrapAgreement, setClickwrapAgreement] = useState(false);
  const [bankStatementAgreement, setBankStatementAgreement] = useState(false);

  const [isTosOpen, setIsTosOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  const ticketsInfo = config.pricingTiers
  const teamsTiers = config?.teams?.[myGetTeamIndex()]?.pledgePerWin?.pledgePerWinPricingTiers
  const eventName = config?.teams?.[myGetTeamIndex()]?.pledgePerWin?.event?.name

  const selectedTicketPackage = config.variant === APP_VARIANTS.CUE ?
    ticketsInfo?.find(
      ({ cue5050PricingTierId }) => cue5050PricingTierId === myGetPricingTierId()
    )
    : teamsTiers?.find(
      ({ pledgePerWinPricingTierId }) => pledgePerWinPricingTierId === myGetPricingTierId()
    )

  const statistic = config?.teams?.[myGetTeamIndex()]?.pledgePerWin?.statistic

  const CreditCardIconComponent =
    creditCardType === "visa"
      ? VisaLogo
      : creditCardType === "mastercard"
        ? MasterCardLogo
        : Fragment

  const onTicketReplaceClick = () => {
    togglePurchaseMenu()
  }

  const handleNameChange = () => {
    goToStepByIndex({
      stepIndex: 0,
      autoFocusFieldName: "firstName",
      returnStepIndex: 2,
    })
  }

  const handleEmailChange = () => {
    goToStepByIndex({
      stepIndex: 0,
      autoFocusFieldName: "email",
      returnStepIndex: 2,
    })
  }

  const handlePhoneNumberChange = () => {
    goToStepByIndex({
      stepIndex: 0,
      autoFocusFieldName: "phoneNumber",
      returnStepIndex: 2,
    })
  }

  const handleCardInfoChange = () => {
    goToStepByIndex({
      stepIndex: 1,
      autoFocusFieldName: "cardNumber",
      returnStepIndex: 2,
    })
  }

  const [isCheckoutInProgress, setIsCheckoutInProgress] = useState(null)
  const [isCheckoutSuccess, setIsCheckoutSuccess] = useState(null)

  const history = useHistory()

  const handleCheckoutSuccess = () => {
    setIsCheckoutSuccess(true)
    setTimeout(() => {
      history.push(`${exactUrl}/checkout-success`)
    }, 2 * SECOND)
  }

  const callCreatePaymentIntent = useCallback(async () => {
    if (!selectedTicketPackage) {
      return
    }

    try {
      const response = await createPaymentIntent({
        ticketTiers: {
          cue5050PricingTierId: myGetPricingTierId(),
          price: selectedTicketPackage.price,
          description: selectedTicketPackage.description,
          quantity: 1,
        },
        eventId: config.eventId,
        vendorIdentifier: config.vendorIdentifier,
        customerEmail: myGetPersonalInformation().email
      })
      const { client_secret, paymentToken, orderReference } = response.data
      setClientSecret(client_secret)
      const clientSecretParts = client_secret.split("_")
      const paymentId = [clientSecretParts[0], clientSecretParts[1]].join("_")
      setPaymentIntentId(paymentId)
      setPaymentToken(paymentToken)
      setOrderReferenceNumber(orderReference)
      setErrorMessage("")
    } catch (e) {
      setErrorMessage(
        `Creation payment intent failed with error: ${e?.response?.data}. Please check the provided data and try again`
      )
    }
  }, [config, selectedTicketPackage])

  useEffect(() => {
    if (shown && config.variant === APP_VARIANTS.CUE) {
      callCreatePaymentIntent()
    }
  }, [shown, callCreatePaymentIntent])

  const isSubmitButtonBusy = isCheckoutInProgress || isCheckoutSuccess

  const handleCheckout = async () => {
    if (isSubmitButtonBusy) {
      return
    }
    setIsCheckoutInProgress(true)

    let payload = {}

    if (config.variant === APP_VARIANTS.CUE) {
      payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
        },
      })
    }

    if (payload.error) {
      setIsCheckoutInProgress(false)
      setErrorMessage(`Payment fulfill failed`)
      return
    } else {
      setErrorMessage("")
    }

    // handling checkout for CUE app
    if (config.variant === APP_VARIANTS.CUE) {

      try {

        let customerDOB_ISOString

        try {

          customerDOB_ISOString = dayjs(myGetPersonalInformation().dateOfBirth).toISOString()

        } catch {

          customerDOB_ISOString = null
        }

        await finalizeFulfill (
          {
            ticketTiers: {
              cue5050PricingTierId: myGetPricingTierId(),
              price: selectedTicketPackage.price,
              description: selectedTicketPackage.description,
              quantity: 1,
            },
            paymentIntentId,
            eventId: config.eventId,
            vendorIdentifier: config.vendorIdentifier,
            customerEmail: myGetPersonalInformation().email,
            customerPhone: myGetPersonalInformation().phoneNumber,
            customerFirstName: myGetPersonalInformation().firstName,
            customerLastName: myGetPersonalInformation().lastName,
            customerDOB: customerDOB_ISOString,
            referenceNumber: orderReferenceNumber
          },
          { paymentToken }
        )

        setIsCheckoutInProgress(false)
        handleCheckoutSuccess()

      } catch (e) {

        setIsCheckoutInProgress(false)
        setErrorMessage(
          `Payment fullfil intent failed with error: ${e?.response?.data}`
        )
      }
    }

    // handling checkout for PPW app
    if (config.variant === APP_VARIANTS.PPW) {

      try {

        const paymentMethod = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: `${myGetPersonalInformation()?.firstName} ${myGetPersonalInformation().lastName}`,
            email: myGetPersonalInformation().email,
            phone: myGetPersonalInformation().phoneNumber,
            address: {
              city: myGetPersonalInformation().city,
              country: myGetPersonalInformation().country,
              line1: myGetPersonalInformation().addressLine1,
              line2: myGetPersonalInformation().addressLine2,
              postal_code: myGetPersonalInformation().cardZip,
              state: myGetPersonalInformation().state
            },
          }
        });

        try {

          await createSubscription ({
            stripePaymentMethodId: paymentMethod.paymentMethod.id,
            firstName: myGetPersonalInformation()?.firstName,
            lastName: myGetPersonalInformation().lastName,
            email: myGetPersonalInformation().email,
            phone: myGetPersonalInformation().phoneNumber,
            pledgePerWinPricingTierId: myGetPricingTierId(),
            pledgePerWinId: myGetPledgePerWinId(),
            civicAddress: {
              addressLine1: myGetPersonalInformation().addressLine1,
              addressLine2: myGetPersonalInformation().addressLine2,
              city: myGetPersonalInformation().city,
              stateProvince: myGetPersonalInformation().state,
              country: myGetPersonalInformation().country,
              postalCode: myGetPersonalInformation().cardZip
            },
            vendorReference: config.vendorIdentifier
          })

          setIsCheckoutInProgress(false)
          handleCheckoutSuccess()

        } catch (e) {

          setIsCheckoutInProgress(false)
          setErrorMessage(
            e?.response?.data ?
            `We were unable to accept your pledge: ${e?.response?.data}`:
            "We were unable to accept your pledge. Please contact support@cueaudio.com"
          )
          console.log({ subscriptionErr: e })
        }
      } catch (err) {

        console.log({ stripePaymentMethodErr: err })
      }
    }

    mySetPricingTierId(null);
  }

  return (
    <div css={styles({ isSubmitButtonBusy })}>
      <h1 className="head">Your {config.variant === APP_VARIANTS.PPW ? `Pledge Per ${statistic}` : 'Purchase'}</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div>{ticketText}</div>
      <div className="checkout-confirmation-item-container">
        <CheckoutConfirmationItem
          label="Name"
          value={`${myGetPersonalInformation().firstName} ${myGetPersonalInformation().lastName}`}
          onChangeClick={handleNameChange}
          disabled={isSubmitButtonBusy}
        />
      </div>
      <div className="checkout-confirmation-item-container">
        <CheckoutConfirmationItem
          label="Email"
          value={myGetPersonalInformation().email}
          onChangeClick={handleEmailChange}
          disabled={isSubmitButtonBusy}
        />
      </div>
      <div className="checkout-confirmation-item-container">
        <CheckoutConfirmationItem
          label="Phone number"
          value={myGetPersonalInformation().phoneNumber || "Not specified"}
          onChangeClick={handlePhoneNumberChange}
          disabled={isSubmitButtonBusy}
        />
      </div>
      <div className="checkout-confirmation-item-container">
        <CheckoutConfirmationItem
          label="Payment method"
          value="****"
          IconComponent={CreditCardIconComponent}
          onChangeClick={handleCardInfoChange}
          disabled={isSubmitButtonBusy}
        />
      </div>
      {selectedTicketPackage && (
        <div className="price-summary">
          <div className="total">
            <span className="total-label">Total:</span>
            <span className="total-value">${(selectedTicketPackage.price / 100).toFixed(2)}</span>
          </div>
        </div>
      )}
      <div className="clickwrap-agreement-container">
        <div className="agreement-checkbox">
          <input
            type="checkbox"
            value={clickwrapAgreement}
            onClick={(event) => setClickwrapAgreement(event.target.checked)}
          />
        </div>
        <span 
          className="clickwrap-agreement"
        >
            By clicking the checkbox, you agree to the {config.variant === APP_VARIANTS.PPW ? 'Pledge Per Win' : 'Cue 5050'} Platform&nbsp;
              <a 
                className="clickwrap-link" 
                onClick={() => setIsTosOpen(true)}
              >
                  Terms of Use
              </a>, 
            including the {config.variant === APP_VARIANTS.PPW ? 'Pledge Per Win' : 'Cue 5050'}&nbsp; 
              <a 
                className="clickwrap-link" 
                onClick={() => setIsPrivacyOpen(true)}
              >
                  Privacy Policy
              </a>
        </span>
      </div>
      
      {config.variant === APP_VARIANTS.PPW && 
        <div className="bank-statement-agreement-container">
          <div className="agreement-checkbox">
            <input
              type="checkbox"
              value={bankStatementAgreement}
              onClick={(event) => setBankStatementAgreement(event.target.checked)}
            />
          </div>
          <div>
            <span className="bank-statement-preview"><b>{ config.bankStatementPrefix }* { eventName }</b></span>
            <br></br>
            <span className="bank-statement-agreement">Your donation will appear on your statement as shown above. 
            Before completing the pledge you must accept this notice.</span>
          </div>
        </div>
      }
      <div className="submit-button-container">
      <button
          style={{ width: "100%", marginRight: "10px" }}
          className="action-button back"
          type="button"
          disabled={isSubmitButtonBusy}
          onClick={() => goToStepByIndex({
            stepIndex: 1,
            autoFocusFieldName: "firstName",
            returnStepIndex: 2,
          })}
        >
          Back
        </button>
        <button
          className="action-button mobile-widths"
          type="button"
          onClick={handleCheckout}
          disabled={!myGetPricingTierId() || 
            (config.variant === APP_VARIANTS.CUE && !clientSecret) || 
            !clickwrapAgreement || 
            (config.variant === APP_VARIANTS.PPW && !bankStatementAgreement)}
        >
          {isCheckoutInProgress ? (
            <img
              className="submit-button-animation"
              src="/animations/processing.gif"
              alt="Checkout in progress animation"
            />
          ) : isCheckoutSuccess ? (
            <img
              className="submit-button-animation"
              src="/animations/success.gif"
              alt="Checkout success animation"
            />
          ) : config.variant === APP_VARIANTS.PPW ? (
            "Pledge"
          ) : (
            "Buy"
          )}
        </button>
      </div>
      {isTosOpen && <Rodal 
        visible={isTosOpen} 
        enterAnimation="slideUp"
        leaveAnimation="slideDown" 
        onClose={() => setIsTosOpen(false)}
        closeMaskOnClick={false}
        >
          <div className="modalHeader">
            <h5 className="heading">Pledge Per Win Terms of Service</h5>
          </div>
          <div className="modalContent" dangerouslySetInnerHTML={ {__html: config.tosHtml.replaceAll('{siteName}', origin)} }>
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
              <button
                className="cancelBtn"
                onClick={() => setIsTosOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
      </Rodal>}
      {isPrivacyOpen && <Rodal 
        visible={isPrivacyOpen} 
        enterAnimation="slideUp"
        leaveAnimation="slideDown" 
        onClose={() => setIsPrivacyOpen(false)}
        closeMaskOnClick={false}
        >
          <div className="modalHeader">
            <h5 className="heading">Pledge Per Win Privacy Policy</h5>
          </div>
          <div className="modalContent" dangerouslySetInnerHTML={ {__html: config.privacyHtml.replaceAll('{siteName}', origin)} }>
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
              <button
                className="cancelBtn"
                onClick={() => setIsPrivacyOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
      </Rodal>}
    </div>
  )
}

CheckoutConfirmation.defaultProps = {
  togglePurchaseMenu: () => { },
  goToStepByIndex: () => { },
  shown: false,
}

CheckoutConfirmation.propTypes = {
  togglePurchaseMenu: PropTypes.func,
  goToStepByIndex: PropTypes.func,
  shown: PropTypes.bool,
}
