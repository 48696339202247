import React, { useState, useMemo, useCallback, useContext } from "react"
import * as yup from "yup"
import axios from "axios"
import SingleFieldQuestionForm from "components/SingleFieldQuestionForm/SingleFieldQuestionForm"
import Footer from "components/Footer/Footer"
import { ConfigContext } from "contexts/ConfigContext"
import { API_BASE_URL, WHERE_ARE_MY_TICKETS } from "config"

export default function WhereAreMyTickets() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [myTicketsStatus, setMyTicketsStatus] = useState({
    fetched: false,
    fetchFailed: false,
  })
  const { config } = useContext(ConfigContext)

  const handleSubmit = useCallback(
    async (values) => {
      setIsSubmitting(true)

      const url = `${API_BASE_URL}${WHERE_ARE_MY_TICKETS}${config.eventReference}/${values.email}`

      try {
        await axios.get(url)
        setMyTicketsStatus({ fetched: true })
      } catch (e) {
        setMyTicketsStatus((prevStatus) => ({
          ...prevStatus,
          fetchFailed: true,
        }))
      }
      setIsSubmitting(false)
    },
    [config.eventReference]
  )

  const fieldName = "email"

  const schema = useMemo(
    () =>
      yup.object().shape({
        [fieldName]: yup
          .string()
          .email("Wrong email format")
          .required("Required"),
      }),
    [fieldName]
  )

  return (
    <>
      <SingleFieldQuestionForm
        head="Where are my tickets"
        paragraph="Enter your email below. If there is an order associated with this email we will resend to the address on file."
        fieldName={fieldName}
        fieldPlaceholder="Email"
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        buttonText="Submit"
        schema={schema}
        showResponseView={myTicketsStatus.fetched}
        responseViewHead={"Thank you."}
        responseViewParagraph={
          "If there is an order associated with this email it has been resent. Please make sure to check your junk and spam folders."
        }
        errorMessage={
          myTicketsStatus.fetchFailed ? "Fetching tickets status failed" : ""
        }
      />
      <Footer />
    </>
  )
}
