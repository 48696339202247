import { useState } from "react"
import PropTypes from "prop-types"
import ExpandPlusIcon from "components/ExpandPlusIcon/ExpandPlusIcon"
import ExpandMinusIcon from "components/ExpandMinusIcon/ExpandMinusIcon"
import { styles } from "./ExpandableItem.styles"

export default function ExpandableItem({ head, paragraph }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleItem = () => setIsExpanded(!isExpanded)

  return (
    <button type="button" onClick={toggleItem} css={styles({ isExpanded })}>
      <div className="head">
        <div className="head-text">{head}</div>
        {isExpanded ? <ExpandMinusIcon /> : <ExpandPlusIcon />}
      </div>
      <p className="paragraph">{paragraph}</p>
    </button>
  )
}

ExpandableItem.defaultProps = {
  head: "",
  paragraph: "",
}

ExpandableItem.propTypes = {
  head: PropTypes.string,
  paragraph: PropTypes.string,
}
