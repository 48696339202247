import axios from "axios"
import { API_BASE_URL, CHECKOUT_API_ROUTES } from "config"

export const createPaymentIntent = async ({
  ticketTiers: { cue5050PricingTierId, description, price, quantity },
  eventId,
  vendorIdentifier,
  customerEmail
}) => {
  try {
    const url = `${API_BASE_URL}${CHECKOUT_API_ROUTES.CREATE_PAYMENT_INTENT}`
    const data = {
      ticketTiers: [
        {
          cue5050PricingTierId,
          description,
          price,
          quantity,
        },
      ],
      fundraisingEventId: eventId,
      paymentMethodTypes: ["card"],
      currency: "usd",
      vendorIdentifier,
      customerEmail
    }
    const response = await axios.post(url, data)
    return response
  } catch (e) {
    throw e
  }
}

export const finalizeFulfill = async (
  {
    ticketTiers: { cue5050PricingTierId, description, price, quantity },
    paymentIntentId,
    eventId,
    vendorIdentifier,
    customerEmail,
    customerPhone,
    customerFirstName,
    customerLastName,
    customerDOB,
    referenceNumber
  },
  { paymentToken }
) => {
  try {
    const url = `${API_BASE_URL}${CHECKOUT_API_ROUTES.FINALIZE_FULFILL}`
    const data = {
      ticketTiers: [
        {
          cue5050PricingTierId,
          description,
          price,
          quantity,
        },
      ],
      paymentIntentId,
      fundraisingEventId: eventId,
      paymentMethodTypes: ["card"],
      currency: "usd",
      vendorIdentifier,
      customerEmail,
      customerPhone,
      customerFirstName,
      customerLastName,
      customerDOB,
      referenceNumber
    }
    const config = {
      headers: { Authorization: `Bearer ${paymentToken}` },
    }
    const response = await axios.post(url, data, config)
    return response
  } catch (e) {
    throw e
  }
}
