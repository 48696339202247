import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ isOpen }) => css`
  height: 18px;
  width: 30px;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 101;

  .menu-button-stick {
    background: ${isOpen ? "#ffffff" : "#111319"};
    border-radius: 1px;
    height: 2px;
    position: absolute;
    transform-origin: left center;
    transition: 0.25s ease-in-out;

    &--top {
      top: ${isOpen ? "1px" : "2px"};
      left: ${isOpen ? "8px" : "5px"};
      width: 20px;
      transform: ${isOpen ? "rotate(45deg)" : "none"};
    }

    &--middle {
      top: 8px;
      left: 0;
      width: ${isOpen ? "0" : "30px"};
      opacity: ${isOpen ? "0" : "1"};
    }

    &--bottom {
      top: ${isOpen ? "15px" : "14px"};
      left: ${isOpen ? "8px" : "5px"};
      width: 20px;
      transform: ${isOpen ? "rotate(-45deg)" : "none"};
    }
  }

  ${mediaQueries.tabletScreen} {
    height: 25px;
    width: 41px;

    .menu-button-stick {
      border-radius: 2px;
      height: 3px;

      &--top {
        top: ${isOpen ? "1px" : "3px"};
        left: ${isOpen ? "11px" : "7px"};
        width: 27px;
      }

      &--middle {
        top: 11px;
        left: 0;
        width: ${isOpen ? "0" : "41px"};
        opacity: ${isOpen ? "0" : "1"};
      }

      &--bottom {
        width: 27px;
        top: ${isOpen ? "20px" : "19px"};
        left: ${isOpen ? "11px" : "7px"};
      }
    }
  }
`
