import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ isSponsorInfoShown }) => css`
  margin-top: 80px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  .sponsor-image-container {
    width: 100%;
    height: 300px;
  }

  .sponsor-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .sponsor-image-blur {
    display: none;
  }

  .sponsor-info-container {
    position: absolute;
    top: 0;
    left: ${isSponsorInfoShown ? "0" : "calc(-100% + 48px)"};
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #f5f7ff;
    z-index: 2;
    transition: 0.25s ease-in-out;
  }

  .sponsor-info {
    padding: 20px 68px 20px 20px;
  }

  .sponsor-logo-container {
    img {
      width: 90px;
      margin-bottom: 16px;
    }
  }

  .sponsor-description {
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #898b93;
  }

  .sponsor-button-toggle-container {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: rotate(90deg) translateX(24px) translateY(24px);
    transform-origin: right;
  }

  .sponsor-button-toggle {
    background-color: #111319;
    border-radius: 10px;
    width: 300px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 17px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #45474d;
    }
  }

  .sponsor-button-toggle-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: #aaacb4;
    transform: rotate(180deg);
  }

  .toggle-arrow-icon {
    width: 16px;
    transform: rotate(${isSponsorInfoShown ? "90deg" : "-90deg"});
  }

  ${mediaQueries.desktopMediumScreen} {
    .sponsor-image-blur {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: ${isSponsorInfoShown ? "block" : "none"};
      transition: 0.25s ease-in-out;
    }

    .sponsor-info-container {
      width: 430px;
      left: ${isSponsorInfoShown ? "0" : "-382px"};
    }

    .sponsor-info {
      padding: 30px 78px 30px 30px;
    }
  }

  ${mediaQueries.desktopLargeScreen} {
    .sponsor-image-container {
      height: 400px;
    }

    .sponsor-button-toggle {
      width: 400px;
    }
  }
`
