import { useState, useEffect, useContext } from "react"
import { Switch, Route, useLocation } from "react-router-dom"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import Home from "pages/cue/Home/Home"
import Contact from "pages/cue/Contact/Contact"
import AmIAWinner from "pages/cue/AmIAWinner/AmIAWinner"
import WhereAreMyTickets from "pages/cue/WhereAreMyTickets/WhereAreMyTickets"
import NotFound from "pages/shared/NotFound/NotFound"
import Pot from "pages/cue/Pot/Pot"
import Checkout from "pages/shared/Checkout/Checkout"
import FAQ from "pages/cue/FAQ/FAQ"
import Legal from "pages/cue/Legal/Legal"
import History from "pages/cue/History/History"
import CheckoutSuccess from "pages/cue/CheckoutSuccess/CheckoutSuccess"
import ContactSuccess from "pages/cue/ContactSuccess/ContactSuccess"
import Header from "components/Header/Header"
import PurchaseBar from "components/PurchaseBar/PurchaseBar"
import Global from "styles/Global"
import { ConfigContext } from "contexts/ConfigContext"
import useUrl from "hooks/useUrl"
import { styles } from "./App.styles"
// import Subscriptions from "pages/ppw/Subscriptions/Subscriptions"
import { APP_VARIANTS } from "config"
import Subscriptions from "pages/ppw/GetLoginLink/GetLoginLink"
import ManageSubscriptions from "pages/ppw/ManageSubscriptions/ManageSubscriptions"
import Helmet from "react-helmet"


const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc:
        "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap",
    },
  ],
}

export default function App() {
  const { config } = useContext(ConfigContext)
  const { pathUrl } = useUrl()

  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const [stripePromise, setStripePromise] = useState(null)
  useEffect(() => {
    if (config.publicStripeKey) {
      try {
        const promise = loadStripe(config.publicStripeKey)
        setStripePromise(promise)
      } catch (err) {
        console.error('Something went wrong while initializing stripe')
      }
    }
  }, [config.publicStripeKey])

  const [isPurchaseMenuOpen, setIsPurchaseMenuOpen] = useState(false)
  const togglePurchaseMenu = () => {
    setIsPurchaseMenuOpen(!isPurchaseMenuOpen)
  }

  useEffect(() => {
    document.body.style.overflowY =
      isMenuOpen || isPurchaseMenuOpen ? "hidden" : "visible"
  }, [isMenuOpen, isPurchaseMenuOpen])

  useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  const ticketsPurchaseAvailable = config.ticketsPurchaseAvailable

  return (
    <div css={styles({ ticketsPurchaseAvailable })}>
      <Helmet>
        <meta name="description" content={config.variant === APP_VARIANTS.PPW ? "PLEDGE" : "50/50"} />
        <title>{config.variant === APP_VARIANTS.PPW ? "Pledge Per Win" : "CUE5050"}</title>
      </Helmet>
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <Global config={config} />
        <Switch>
          <Route path={`${pathUrl}/checkout`}>
            <Checkout />
          </Route>
          <Route path={`${pathUrl}/checkout-success`}>
            <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} fullWidth />
            <CheckoutSuccess />
          </Route>
          <Route path={`${pathUrl}/contact-us-success`}>
            <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} fullWidth />
            <ContactSuccess />
          </Route>
          <Route path={`${pathUrl}/pot`}>
            <Pot />
          </Route>
          <Route path={`${pathUrl}/privacy`}>
            <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} fullWidth />
            <div className="page" dangerouslySetInnerHTML={ {__html: config.privacyHtml} }></div>
          </Route>
          <Route path={`${pathUrl}/tos`}>
            <Header isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} fullWidth />
            <div className="page" dangerouslySetInnerHTML={ {__html: config.tosHtml} }></div>
          </Route>
          <Route>
            <div className="page">
              <Header
                isMenuOpen={isMenuOpen}
                toggleMenu={toggleMenu}
                fullWidth={!ticketsPurchaseAvailable}
              />
              <Switch>
                <Route
                  exact
                  path={`${pathUrl}/am-i-a-winner`}
                  component={AmIAWinner}
                />
                <Route
                  exact
                  path={`${pathUrl}/where-are-my-tickets`}
                  component={WhereAreMyTickets}
                />
                <Route exact path={`${pathUrl}/contact`} component={Contact} />
                <Route exact path={`${pathUrl}/faq`} component={FAQ} />
                <Route exact path={`${pathUrl}/history`} component={History} />
                <Route exact path={`${pathUrl}/legal`} component={Legal} />
                <Route exact path={`${pathUrl}/`} component={Home} />
                <Route exact path={`${pathUrl}/get-login-link`} component={Subscriptions} />
                <Route exact path={`${pathUrl}/subscription-management`} component={ManageSubscriptions} />
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </div>
            {ticketsPurchaseAvailable && (
              <div className="purchase-bar">
                <PurchaseBar
                  isMenuOpen={isPurchaseMenuOpen}
                  toggleMenu={togglePurchaseMenu}
                  backButtonHandler={togglePurchaseMenu}
                  align="right"
                />
              </div>
            )}
          </Route>
        </Switch>
      </Elements>
    </div>
  )
}
