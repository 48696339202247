import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`
  margin-top: 80px;

  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
    color: #111319;
    margin-bottom: 16px;
  }

  .full-history-link-container {
    margin-top: 20px;
  }

  .record {
    &:nth-of-type(n + 3) {
      display: none;
    }
  }

  ${mediaQueries.mobileLargeScreen} {
    .record {
      &:nth-of-type(n + 3) {
        display: flex;
      }
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    .full-history-link-container {
      width: 256px;
    }
  }
`
