import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`
padding-top: 30px;

.title {
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 110%;
  letter-spacing: -1px;
  color: #111319;
}

${mediaQueries.tabletScreen} {
  padding-top: 60px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    letter-spacing: -2px;
    color: #111319;
  }
}

${mediaQueries.desktopSmallScreen} {
  padding-top: 90px;
}
`
