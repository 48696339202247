import { css } from "@emotion/react"
import { colors } from "styles/config"

export const field = ({ error }) => css`
  background-color: #fbfcff;
  border: 1px solid ${error ? colors.error : "#e5e7f0"};
  border-radius: 10px;
  padding: 12px 20px;
  color: #111319;
  font-size: 16px;
  line-height: 140%;
  outline: none;
  width: 100%;

  &:hover {
    border-color: ${error ? colors.error : "#ced1d9"};
  }

  &:focus {
    border-color: ${error ? colors.error : "#cbe1ef"};
    background-color: transparent;
  }
`

export const label = () => css`
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #111319;
  display: inline-block;
  margin-bottom: 4px;
`
