import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ config }) => css`


  .title-container {
    margin-top:30px;
    padding: 20px;
    background: ${config.primaryColor};
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
    color: #ffffff;
  }

  .row{
    display: flex;
  }
  
  .checkout-nav-mobile {
    background-color: #111319;
    display: flex;
    padding: 18px 20px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .step-status {
    color: #aaacb4;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.5px;
  }
  .checkout-nav-desktop {
    background-color: white;
    z-index:1;
    display: none;
  }
  ${mediaQueries.tabletScreen} {
    .checkout-nav-mobile {
      padding: 28px 40px;
    }
  }
  ${mediaQueries.desktopSmallScreen} {
    .checkout-nav-mobile {
      display: none;
    }
    .checkout-nav-desktop {
      background-color: white;
      z-index:1;
      display: flex;
      flex-direction: column;
      padding: 12px 15px;
      position: fixed;
      top: 0;
      left: 256px;
      right: 0;
    }
    .step {
      flex: 1;
      font-size: 14px;
      line-height: 120%;
      text-align: center;
      letter-spacing: -0.5px;
      color: #111319;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 2px solid transparent;
      margin-left: 15px;
      margin-right: 15px;
      cursor: pointer;

      &--upcoming {
        color: #898b93;
      }

      &--current {
        border-color: #aaacb4;
      }

      &--completed {
        border-color: ${config.primaryColor};
      }
    }
  }

  ${mediaQueries.desktopMediumScreen} {
    .checkout-nav-desktop {
      background-color: white;
      z-index:1;
      left: 360px;
    }
  }

  ${mediaQueries.desktopLargeScreen} {
    .checkout-nav-desktop {
      background-color: white;
      z-index:1;
      left: 480px;
    }
  }
`
