import { styles } from "./Select.styles"

export default function Select({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  placeholder: {title, value},
  options,
  ...props
}) {
  const inputRef = props.inputRef
  delete props.inputRef

  const optionElements = options?.map(
      ({title, value}) => (
        <option key={value || title} value={value || title}>{title}</option>
    )
  ) 

  return (
    <div css={styles({ error: touched[field.name] && errors[field.name] })}>
      {props.label && (
        <label className="label" htmlFor={props.id || props.name}>
          {props.label}
        </label>
      )}
      <select className="input" ref={inputRef} {...field} {...props}>
          <option value={value}>{title}</option>
          {optionElements}
      </select>
    </div>
  )
}