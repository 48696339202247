import { useContext } from "react"
import { Link } from "react-router-dom"
import LinkIconWhite from "assets/icons/link-icon-white.svg"
import WinningsTable from "components/WinningsTable/WinningsTable"
import { ConfigContext } from "contexts/ConfigContext"
import useUrl from "hooks/useUrl"
import { styles } from "./RecentWinnings.styles"

export default function RecentWinnings() {
  const { exactUrl } = useUrl()

  const {
    config: { recentWinnings },
  } = useContext(ConfigContext)

  const colNames = recentWinnings[0]
  const data = recentWinnings.slice(1)

  if (!data.length) {
    return null
  }

  return (
    <div css={styles()}>
      <h2 className="title">Recent winnings</h2>
      <div className="recent-winnings-table">
        <WinningsTable colNames={colNames} data={data.slice(0, 10)} />
      </div>
      {data.length > 10 && (
        <div className="full-history-link-container">
          <Link to={`${exactUrl}/history`} className="regular-button">
            Full history of winnings <img src={LinkIconWhite} alt="Link" />
          </Link>
        </div>
      )}
    </div>
  )
}
