import { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import CreditCardIcon from "assets/icons/credit-card.svg"
import BackButton from "components/BackButton/BackButton"
import { PurchaseContext } from "contexts/PurchaseContext"
import { ConfigContext } from "contexts/ConfigContext"
import useUrl from "hooks/useUrl"
import { styles } from "./PurchaseBar.styles"
import { APP_VARIANTS } from "config"

export default function PurchaseBar({
  isMenuOpen,
  toggleMenu,
  backButtonHandler,
  backButtonText,
  align,
  alterCheckout,
}) {
  const { myGetPricingTierId, mySetPricingTierId, myGetTeamIndex } = useContext(PurchaseContext)
  const { config } = useContext(ConfigContext)
  const history = useHistory()
  const { exactUrl } = useUrl()

  const variant = config.variant
  const ticketsData = config.pricingTiers
  const teamsTiers = config?.teams?.[myGetTeamIndex()]?.pledgePerWin?.pledgePerWinPricingTiers
  const statistic = config?.teams?.[myGetTeamIndex()]?.pledgePerWin?.statistic
  const title = variant === APP_VARIANTS.CUE ? 'Purchase raffle tickets' : statistic ? `Pledge Per ${statistic}` : ''

  const handleTicketClick = ({ pricingTierId }) => {
    
    mySetPricingTierId(pricingTierId);

    if (!history.location.pathname.includes('checkout')) {

      history.push(`${exactUrl}/checkout`);
    }

    if (isMenuOpen) {
      toggleMenu()
    }
  }

  const cueTickets = ticketsData?.map(
    ({ cue5050PricingTierId, description, price, amount }) => (
      <li
        key={cue5050PricingTierId}
        className={`ticket-item ${alterCheckout && cue5050PricingTierId === +myGetPricingTierId()
          ? "ticket-item--selected"
          : ""
          }`}
      >
        <button
          type="button"
          className="ticket-button"
          onClick={() =>
            handleTicketClick({ pricingTierId: cue5050PricingTierId })
          }
        >
          {description} for ${Math.floor(price / 100)} ({amount} tickets)
        </button>
      </li>
    )
  )

  const ppwTickets = teamsTiers?.map(
    ({ price, pledgePerWinPricingTierId }) => (
      <li
        key={pledgePerWinPricingTierId}
        className={`ticket-item ${alterCheckout && pledgePerWinPricingTierId === +myGetPricingTierId()
          ? "ticket-item--selected"
          : ""
          }`}
      >
        <button
          type="button"
          className="ticket-button"
          onClick={() =>
            handleTicketClick({ pricingTierId: pledgePerWinPricingTierId })
          }
        >
          ${parseFloat(price/100).toString().includes('.') ? 
            parseFloat(price/100).toFixed(2) : 
            parseFloat(price/100)}
        </button>
      </li>
    )
  )

  const tickets = config.variant === APP_VARIANTS.PPW ? ppwTickets : cueTickets

  return (
    <div css={styles({ isOpen: isMenuOpen, align, alterCheckout, config })}>
      <div className="purchase-content">
        <div className="purchase-content-text">
          <div className="back-button-container">
            <BackButton
              onClick={backButtonHandler}
              buttonText={backButtonText}
            />
          </div>
          <div className="icon-container">
            <img src={CreditCardIcon} alt="Credit Card" />
          </div>
          <h2 className="title">{title}</h2>
          {!tickets && (
            <h3 className="no-tickets-available">No tickets available</h3>
          )}
        </div>
        {tickets && <ul className="tickets-container">
          {tickets}
        </ul>}
      </div>
      {!alterCheckout && (
        <button
          type="button"
          className="action-button menu-button"
          onClick={toggleMenu}
        >
          {title}
        </button>
      )}
    </div>
  )
}

PurchaseBar.defaultProps = {
  tickets: [],
  toggleMenu: () => { },
  align: "right",
  alterCheckout: false,
}

PurchaseBar.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.object),
  toggleMenu: PropTypes.func,
  isMenuOpen: PropTypes.bool.isRequired,
  align: PropTypes.oneOf(["left", "right"]),
  alterCheckout: PropTypes.bool,
  backButtonText: PropTypes.string,
}
