import PropTypes from "prop-types"
import { styles } from "./CheckoutConfirmationItem.styles"

export default function CheckoutConfirmationItem({
  label,
  value,
  IconComponent,
  onChangeClick,
  disabled,
}) {
  return (
    <div css={styles()}>
      <div className="label">{label}</div>
      <div className="value-container">
        <div className="value-content">
          {IconComponent && (
            <div className="icon-container">
              <IconComponent />
            </div>
          )}
          <div className="value">{value}</div>
        </div>
        <button
          type="button"
          className="change-button"
          onClick={onChangeClick}
          disabled={disabled}
        >
          Change
        </button>
      </div>
    </div>
  )
}

CheckoutConfirmationItem.defaultProps = {
  label: "",
  value: "",
  onChangeClick: () => {},
  disabled: false,
}

CheckoutConfirmationItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  IconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.symbol]),
  onChangeClick: PropTypes.func,
  disabled: PropTypes.bool,
}
