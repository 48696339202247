import React, { useState, useMemo } from "react"
import { useLocation } from "react-router-dom"

export const PurchaseContext = React.createContext({})

export const PurchaseProvider = ({ children }) => {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const [pricingTierId, setPricingTierId] = useState(
    +urlSearchParams.get("pricing-tier-id")
  )
  const [personalInformation, setPersonalInformation] = useState({})
  const [paymentMethod, setPaymentMethod] = useState({})
  const [creditCardType, setCreditCardType] = useState(null)
  // for ppw website only
  const [teamIndex, setTeamIndex] = useState(null)
  const [pledgePerWinId, setPledgePerWinId] = useState(null)

  const mySetPersonalInformation = (value) => {

    localStorage.setItem("personalInformation", JSON.stringify(value));
    setPersonalInformation(value);
  }

  const myGetPersonalInformation = () => {

    return JSON.parse(localStorage.getItem("personalInformation")) || personalInformation;
  }

  const mySetTeamIndex = (value) => {

    localStorage.setItem("teamIndex", value);
    setTeamIndex(value);
  }

  const myGetTeamIndex = () => {

    return localStorage.getItem("teamIndex") || teamIndex;
  }

  const mySetPledgePerWinId = (value) => {

    localStorage.setItem("pledgePerWinId", value);
    setPledgePerWinId(value);
  }

  const myGetPledgePerWinId = () => {

    return localStorage.getItem("pledgePerWinId") || pledgePerWinId;
  }

  const mySetPricingTierId = (value) => {

    localStorage.setItem("pricingTierId", value);
    setPricingTierId(value);
  }

  const myGetPricingTierId = () => {

    return +localStorage.getItem("pricingTierId") || +pricingTierId;
  }

  const contextGetters = useMemo(
    () => ({
      myGetPricingTierId,
      myGetPersonalInformation,
      myGetTeamIndex,
      myGetPledgePerWinId
    }),
    [
      pricingTierId,
      personalInformation,
      teamIndex,
      pledgePerWinId
    ]
  );

  return (
    <PurchaseContext.Provider
      value={{
        ...contextGetters,
        mySetPricingTierId,
        mySetPersonalInformation,
        paymentMethod,
        setPaymentMethod,
        creditCardType,
        setCreditCardType,
        mySetTeamIndex,
        mySetPledgePerWinId
      }}
    >
      {children}
    </PurchaseContext.Provider>
  )
}
