import { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { ConfigContext } from "contexts/ConfigContext"
import useUrl from "hooks/useUrl"
import MenuButton from "./MenuButton/MenuButton"
import { styles } from "./Header.styles"
import { APP_VARIANTS } from "config"
import React from 'react'

export default function Header({ isMenuOpen, toggleMenu, fullWidth }) {
  const { config } = useContext(ConfigContext)
  const { exactUrl } = useUrl()
  const { recentWinnings } = config

  const { variant } = config
  return (
    <header css={styles({ isMenuOpen, fullWidth })}>
      <div className="logo-container">
        <Link to={`${exactUrl}/`}>
          <img className="logo-image" src={config.logoUrl} alt="Logo" />
          {config.variant === APP_VARIANTS.CUE && (
            <span className="logo-text">{config.vendorName}</span>
          )}
        </Link>
      </div>
      <div className="menu-container">
        <ul className="menu">
          {
            variant === APP_VARIANTS.CUE &&
            <>
              <li className="menu-item">
                <Link to={`${exactUrl}/`}>Home</Link>
              </li>
              {recentWinnings?.length > 1 && (
                <li className="menu-item">
                  <Link to={`${exactUrl}/history`}>History</Link>
                </li>
              )}
              <li className="menu-item">
                <Link to={`${exactUrl}/faq`}>FAQ</Link>
              </li>
              <li className="menu-item">
                <Link to={`${exactUrl}/contact`}>Contact</Link>
              </li>
              <li className="menu-item">
                <Link to={`${exactUrl}/where-are-my-tickets`}>Where are my tickets?</Link>
              </li>
              <li className="menu-item">
                <Link to={`${exactUrl}/am-i-a-winner`}>Am I a winner?</Link>
              </li>
            </>
          }
          {
            variant === APP_VARIANTS.PPW &&
            <div>
              <li className="menu-item">
                <Link to={`${exactUrl}/get-login-link`}>My Subscriptions</Link>
              </li>
            </div>
          }
        </ul>
      </div>
      <div className="menu-button-container">
        <MenuButton isOpen={isMenuOpen} onClick={toggleMenu} />
      </div>
    </header>
  )
}

Header.defaultProps = {
  fullWidth: false,
}

Header.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
