import { useEffect, useState, useCallback, useContext } from "react"
import axios from "axios"
import dayjs from "utils/dayjs"
import { API_BASE_URL, HISTORY_API_ROUTE_BASE } from "config"
import Footer from "components/Footer/Footer"
import WinningsTable from "components/WinningsTable/WinningsTable"
import { ConfigContext } from "contexts/ConfigContext"
import { styles } from "./History.styles"

const colNames = ["Name", "Ticket Number", "Date", "Game", "Total Pot"]
const colWidths = ["20%", "27%", "15%", "25%", "13%"]

const formHistoryRecords = (data) => {
    const now = new Date(Date.now());
    now.setMonth(now.getMonth() - 3);
    const threeMonthsAgo = now;
    
    data = data.filter(o => o?.date?.getTime() >= threeMonthsAgo.getTime());
    
    return data.map(({winningTicketNumber, date, eventName, totalPrize, name}) => [
        name,
        winningTicketNumber,
        dayjs(date).format("MM/DD/YYYY"),
        eventName,
        `$${(totalPrize / 100)?.toLocaleString()}`,
    ])
}

export default function History() {
  const [historyRecords, setHistoryRecords] = useState([])
  const { config } = useContext(ConfigContext)

  const fetchHistoryData = useCallback(async () => {
    const url = `${API_BASE_URL}${HISTORY_API_ROUTE_BASE}${config.vendorIdentifier}`
    try {
      const { data } = await axios.get(url)
      const records = formHistoryRecords(data)
      setHistoryRecords(records)
    } catch (e) {
      // can't retrieve history
    }
  }, [config.vendorIdentifier])

  useEffect(() => {
    fetchHistoryData()
  }, [fetchHistoryData])

  return (
    <div css={styles()}>
      <h2 className="title">History of winnings</h2>
      <div className="history-table">
        <WinningsTable
          colNames={colNames}
          colWidths={colWidths}
          data={historyRecords.slice(0, 10)}
        />
      </div>
      <Footer />
    </div>
  )
}
