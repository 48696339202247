import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ config }) => css`
  border: 1px solid ${config.primaryColor};
  border-radius: 10px;
  overflow: hidden;
  margin-top: 40px;

  .raffle-pot-size-container {
    background-color: ${config.primaryColor};
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .raffle-pot-title {
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.5px;
  }

  .raffle-pot-announcement {
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: -0.5px;
    margin-bottom: 20px;
  }

  .raffle-pot-day {
    font-weight: bold;
  }

  .raffle-pot-amount {
    text-align: center;
    color: #ffffff;
    letter-spacing: -2px;
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    margin-bottom: 20px;
  }

  .raffle-pot-amount-text {
    text-align: center;
    color: #ffffff;
    letter-spacing: -0.5px;
    font-size: 14px;
    line-height: 120%;
  }

  .event-start-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .event-start-title {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .event-start-date {
    text-align: center;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: -0.5px;
    color: #111319;
    margin-bottom: 20px;
  }

  .event-start-timer {
    display: flex;
    justify-content: center;
  }

  .event-start-item {
    margin-left: 10px;
    margin-right: 10px;
  }

  .event-start-item-count {
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -2px;
    color: #111319;
    margin-bottom: 20px;
    text-align: center;
  }

  .event-start-item-name {
    text-align: center;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .raffle-pot-size-not-started {
    margin-top: 28px;
    margin-bottom: 45px;
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -2px;
    color: #ffffff;
    text-align: center;
  }

  .event-start-not-started {
    margin-top: 28px;
    margin-bottom: 45px;
    text-align: center;
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    letter-spacing: -2px;
    color: #111319;
  }

  ${mediaQueries.mobileLargeScreen} {
    display: flex;

    .raffle-pot-size-container {
      flex: 1;
    }

    .event-start-container {
      flex: 1;
    }
  }

  ${mediaQueries.tabletScreen} {
    .raffle-pot-title,
    .event-start-title {
      font-size: 16px;
    }

    .event-start-not-started {
      margin-top: 36px;
    }

    .raffle-pot-announcement,
    .event-start-date {
      font-size: 8px;
      margin-bottom: 30px;
    }

    .raffle-pot-amount,
    .event-start-item-count {
      margin-bottom: 30px;
    }

    .raffle-pot-amount-text,
    .event-start-item-name {
      font-size: 10px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    .raffle-pot-size-not-started,
    .event-start-not-started {
      margin-top: 46px;
      font-size: 76px;
      line-height: 100%;
      letter-spacing: -2px;
      margin-bottom: 74px;
    }

    .event-start-not-started {
      margin-top: 28px;
      margin-bottom: 0;
    }

    .raffle-pot-title,
    .event-start-title {
      font-size: 17px;
    }

    .raffle-pot-amount,
    .event-start-item-count {
      font-size: 53px;
    }
  }

  ${mediaQueries.desktopMediumScreen} {
    .raffle-pot-title,
    .event-start-title {
      font-size: 24px;
    }

    .event-start-not-started {
      margin-top: 56px;
    }

    .raffle-pot-announcement,
    .event-start-date {
      font-size: 12px;
      margin-bottom: 44px;
    }

    .raffle-pot-amount,
    .event-start-item-count {
      font-size: 76px;
      margin-bottom: 43px;
    }

    .event-start-item-name,
    .raffle-pot-amount-text {
      font-size: 14px;
    }

    .event-start-item {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  ${mediaQueries.desktopLargeScreen} {
    .raffle-pot-title,
    .event-start-title {
      font-size: 32px;
    }

    .raffle-pot-announcement,
    .event-start-date {
      font-size: 16px;
      margin-bottom: 60px;
    }

    .raffle-pot-amount,
    .event-start-item-count {
      font-size: 103px;
      margin-bottom: 58px;
    }

    .event-start-item-name,
    .raffle-pot-amount-text {
      font-size: 19px;
    }

    .event-start-item {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
`
