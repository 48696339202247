import dayjs from "utils/dayjs"

export const formHistoryData = (data) => {
  const colNames = ["Winning Ticket Number", "Date", "Game", "Total Pot"]

  data = filterRecentWinners(data);

  if (!data) {
    return colNames
  }

  const rows = data.map(
    ({ winningTicketNumber, date, eventName, totalPrize }) => [
      winningTicketNumber,
      dayjs(date).format("MM/DD/YYYY"),
      eventName,
      `$${(totalPrize / 100)?.toLocaleString()}`,
    ]
  )
  return [colNames, ...rows]
}

const filterRecentWinners = (data) => {
  const now = new Date(Date.now());
  now.setMonth(now.getMonth() - 3);
  const threeMonthsAgo = now;
  
 return data.filter(w => new Date(w?.date)?.getTime() >= threeMonthsAgo.getTime());
}

export const formTeamData = (teams) => {

  return teams?.sort((team1, team2) => {

    if (!team1.pledgePerWin) {

      return 1;

    } else if (!team2.pledgePerWin) {

      return -1;

    } else {

      return team1.pledgePerWin.sortOrder - team2.pledgePerWin.sortOrder;
    }
  })
}

export const structureData = (rawData) => ({
  sponsorUrl: rawData.sponsorUrl,
  advertisementText: rawData.advertisementText,
  advertisementUrl: rawData.advertisementUrl,
  advertisementName: rawData.advertisementName,
  variant: rawData.variant,
  teams: formTeamData(rawData?.teams),
  logoUrl: rawData.logoUrl,
  recentGames: rawData.recentGames,
  name: rawData.name,
  bannerUrl: rawData.bannerUrl,
  vendorIdentifier: rawData.vendorReference,
  vendorName: rawData.vendorName,
  primaryColor: rawData.primaryColor,
  secondaryColor: rawData.secondaryColor,
  legalUrl: rawData.legalUrl,
  recentWinnings: formHistoryData(rawData.recentWinners),
  faq: rawData.faq,
  publicStripeKey: rawData.publicStripeKey,
  bankStatementPrefix: rawData.bankStatementPrefix,
  currentEvents: rawData?.currentEvents?.map((event) => ({
    eventId: event.eventId,
    pricingTiers: event.pricingTiers,
    eventName: event.event.name,
    sponsorImageUrl: event.event.sponsorImageUrl,
    sponsorLogoUrl: event.event.sponsorLogoUrl,
    sponsorDescription: event.event.sponsorDescription,
    realStartDateTime: event.event.realStartDateTime,
    realEndDateTime: event.event.realEndDateTime,
    winnerSelectTime: event.winnerSelectTime,
    potSize: event.currentPot,
    active: event.event.active,
    status: event.event.status,
    charityName: event.event.merchant.name,
    charityImageUrl: event.event.merchant.imageUrl,
    charityDescription: event.event.merchant.description,
    charityUrl: event.event.merchant.homePageUrl,
    receiptInfo: event.receiptInfo,
    eventReference: event.event.uniqueIdentifier,
  })),
})

export const EventStatus = ({
  Scheduled: 1,
  InProgress: 2,
  Completed: 3,
  Canceled: 4
})

export const StateAbbreviations = [
  "AL",
  "AK",	
  "AZ",	
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
]
