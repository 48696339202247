import { css } from "@emotion/react"
import { mediaQueries, outerPadding } from "styles/config"

export const styles = () => css`
  padding: 70px ${outerPadding.mobile};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .checkout-success-image-container {
    width: 140px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .paragraph {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #111319;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    margin: -5px;
  }

  .button-container {
    margin: 5px;
  }

  .go-home-button {
    width: 100%;
    justify-content: center;
  }

  .disclaimer {
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.5px;
    color: #898b93;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }

  .content {
    max-width: 400px;
    margin: 0 auto;
  }

  ${mediaQueries.mobileLargeScreen} {
    .buttons-container {
      flex-direction: row;
    }

    .button-container {
      flex: 1;
    }

    .go-home-button {
      padding: 13px 24px;
    }
  }

  ${mediaQueries.tabletScreen} {
    padding: 84px ${outerPadding.tablet};

    .title {
      font-size: 36px;
      line-height: 110%;
      letter-spacing: -1px;
    }

    .disclaimer {
      bottom: 40px;
      left: 40px;
      right: 40px;
    }

    .disclaimer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 420px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    padding: 84px ${outerPadding.desktop};
  }
`
