import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ config, isInViewport }) => css`

  margin-top:25px;

  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    display: none;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;    
    z-index: 1;
    padding: 0.55rem 0.75rem;
  }
  
  .tooltip:hover .tooltiptext {
    display:block;
    position: fixed;
    overflow:hidden;
  }

  .title {
    color: #111319;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }

  .sports-container{
    width:100%;
    display:flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top:30px;
    overflow-x: auto;
  }

  .sports-container::-webkit-scrollbar {
    display: none;
  }

  .sport-card {
    position: relative;
    display:flex;
    flex-direction: column;
    align-items:center;
    justifyContent: space-evenly;
    padding: 1rem;
    min-width: 12rem;
    min-height: 12rem;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    width: 12rem;
    height: 12rem;
    max-height: 300px;
    max-width: 300px;

    img{
      width: 100%;
      height: 100%;
      border-radius: 15px;
    }

    p {
      color: #FFFFFF;
      font-size: 20pt;
      margin-top:20px;
      text-decoration: none;
      font-weight:700;
    }
  }

  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .mobile-tooltip {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: ${config.primaryColor};
    padding: 1.5rem;
    opacity: 0;
    animation-name: ${isInViewport ? 'mobile-tooltip' : 'none'};
    animation-duration: 8s;
    animation-iteration-count: 3;
    border-radius: 15px;
  }

  .text {
    color: white;
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  @keyframes mobile-tooltip {
    0%   {opacity: 0}
    25%   {opacity: 1}
    50%  {opacity: 0}
  }

  ${mediaQueries.desktopSmallScreen} {

    .mobile-tooltip {
      animation-iteration-count: 0;
    }

    .sport-card {
      width: 15rem;
      height: 15rem;
    }
  }
`
