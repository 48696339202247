import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"
import { label } from "styles/mixins"

export const styles = () => css`
  .head {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .credit-card-logos {
    margin-bottom: 20px;
    display: flex;
  }

  .credit-card-logo-container {
    width: 38px;
    margin-right: 14px;

    svg {
      width: 100%;
    }
  }

  .card-number-label {
    ${label()}
  }

  .card-additional-info-container {
    margin-top: 6px;
    display: flex;
  }

  .card-expiry-container {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 104px;
  }

  .card-cvv-container {
    flex: 1;
    display: flex;
  }

  .card-cvv-disclaimer-container {
    flex: 1;
    font-size: 12px;
    line-height: 130%;
    text-align: right;
    letter-spacing: -0.5px;
    color: #aaacb4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
  }

  .card-cvv-input-container {
    flex-basis: 80px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 6px;
  }

  .card-month-container,
  .card-year-container,
  .card-cvv-input-container {
    input {
      text-align: center;
    }
  }

  .field-container {
    margin-bottom: 20px;
  }

  .submit-button-container {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    margin-bottom-30px;
  }

  .mobile-widths {
    width: 100%;
  }

  ${mediaQueries.tabletScreen} {
    .head {
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 110%;
      letter-spacing: -1px;
      margin-top: 30px;
    }

    .credit-card-logos {
      margin-bottom: 30px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    .submit-button-container {
      position: initial;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .head {
      margin-top: 0;
    }

    .mobile-widths{
      width: 100%;
      margin-left: 0;
    }
  }
`
