import axios from "axios"
import { API_BASE_URL, SUBCRIPTIONS_ROUTES } from "config"

export const createSubscription = async ({
  stripePaymentMethodId,
  firstName,
  lastName,
  email,
  phone,
  civicAddress,
  pledgePerWinId,
  pledgePerWinPricingTierId,
  vendorReference
}) => {
  try {
    const url = `${API_BASE_URL}${SUBCRIPTIONS_ROUTES.CREATE_SUBSCRIPTION}`
    const data = {
      stripePaymentMethodId,
      firstName,
      lastName,
      email,
      phone,
      civicAddress,
      pledgePerWinId,
      pledgePerWinPricingTierId,
      vendorReference,
    }
    const response = await axios.post(url, data)
    return response
  } catch (e) {
    throw e
  }
}

export const sendPublicLoginLink = async ({
  pledgerEmail,
  vendorReference,
}) => {
  try {
    const url = `${API_BASE_URL}${SUBCRIPTIONS_ROUTES.SEND_PUBLIC_LOGIN_LINK}`
    const data = {
      vendorReference,
      pledgerEmail,
    }
    const response = await axios.post(url, data)
    return response
  } catch (e) {
    throw e
  }
}

export const getSubscriptions = async ({ token }) => {
  try {
    const url = `${API_BASE_URL}${SUBCRIPTIONS_ROUTES.GET_SUBSCRIPTIONS}`
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    // use when mocking
    // const response = {
    //   data: [
    //     {
    //       subscriptionId: 12,
    //       signUpDate: "2022-06-14T10:55:35.1247816",
    //       eventName: "Pledge Per Homerun",
    //     },
    //     {
    //       subscriptionId: 13,
    //       signUpDate: "2022-06-14T10:55:35.1247816",
    //       eventName: "Pledge Per Goal",
    //     },
    //     {
    //       subscriptionId: 14,
    //       signUpDate: "2022-06-14T10:55:35.1247816",
    //       eventName: "Pledge Per Penalty",
    //     },
    //   ],
    // }
    return response?.data
  } catch (e) {
    throw e
  }
}

export const updateSubscriptions = async ({ token, subscriptionsStatus }) => {
  try {
    const url = `${API_BASE_URL}${SUBCRIPTIONS_ROUTES.UPDATE_SUBSCRIPTIONS}`
    const response = await axios.post(url, subscriptionsStatus, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  } catch (e) {
    throw e
  }
}
