import { useContext } from "react"
import { Link } from "react-router-dom"
import LinkIconWhite from "assets/icons/link-icon-white.svg"
import ExpandableGroup from "components/ExpandableGroup/ExpandableGroup"
import { ConfigContext } from "contexts/ConfigContext"
import useUrl from "hooks/useUrl"
import { styles } from "./TopAskedQuestions.styles"
import { APP_VARIANTS } from "config"

export default function TopAskedQuestions() {
  const { config } = useContext(ConfigContext)
  const { exactUrl } = useUrl()

  return (
    <div css={styles()}>
      <h2 className="title">Frequently Asked Questions</h2>
      <div className="questions-container">
        <ExpandableGroup items={config.faq.slice(0, 5)} />
      </div>
      {config.faq.length > 5 && (
        <div className="faq-link-container">
          <Link to={`${exactUrl}/faq`} className="regular-button">
            Go to FAQ <img src={LinkIconWhite} alt="Link" />
          </Link>
        </div>
      )}
    </div>
  )
}
