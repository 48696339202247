import React, { useContext, useState, useRef } from 'react'
import { styles } from './TemasSection.styles'
import { ConfigContext } from "contexts/ConfigContext"
import { PurchaseContext } from 'contexts/PurchaseContext'
import useUrl from 'hooks/useUrl'
import { useHistory } from 'react-router-dom'
import useIsInViewport from 'hooks/useIsInViewport'


export default function TeamsSection() {
    const { config } = useContext(ConfigContext)
    const { mySetTeamIndex, mySetPledgePerWinId } = useContext(PurchaseContext)
    const history = useHistory()
    const { exactUrl } = useUrl()

    const componentRef = useRef(null);
    const isInViewport = useIsInViewport(componentRef);

    const teams = config?.teams

    for (var team of teams) {

        team.ref = React.createRef();
    }

    const handleClick = (teamIndex) => {
        mySetTeamIndex(teamIndex);
        mySetPledgePerWinId(teams[teamIndex]?.pledgePerWin?.pledgePerWinId);
        history.push(`${exactUrl}/checkout`)
    }

    const showTooltip = (event, index) => {

        const x = event.clientX;
        const y = event.clientY;

        if (teams[index].ref.current) {

            teams[index].ref.current.style.top = (y + 20) + 'px';
            teams[index].ref.current.style.left = (x + 20) + 'px';
        }
    }

    return (
        <div ref={componentRef} css={styles({ config, isInViewport })}>
            <h2 className='title'>Our Campaigns</h2>
            <p>Below you will find all of our active Pledge Campaigns. Please select a campaign to learn more about them and how your support will impact them. Thank you for your support!</p>

            <div className='sports-container'>
                {
                    teams?.map((team, index) => {
                        return (
                            <a onClick={() => handleClick(index)} 
                                className="sport-card tooltip" 
                                onMouseMove={($event) => showTooltip($event, index)}
                                key={team?.teamId}>
                                <div className='container'>
                                    <span ref={ team.ref }className="tooltiptext">{team?.name}</span>
                                    <img src={team.logoUrl} />
                                    <div className='mobile-tooltip'>
                                        <div className="text">
                                            Tap to Pledge to {team?.name}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        )
                    })
                }
            </div>
        </div>
    )
}
