import { useContext } from "react"
import { Link } from "react-router-dom"
import { PurchaseContext } from "contexts/PurchaseContext"
import CheckoutSuccessImage from "assets/images/checkout-success.png"
import useUrl from "hooks/useUrl"
import { styles } from "./CheckoutSuccess.styles"
import { ConfigContext } from "contexts/ConfigContext"
import { APP_VARIANTS } from "config"

export default function CheckoutSuccess() {
  const { config } = useContext(ConfigContext)
  const { myGetPricingTierId } = useContext(PurchaseContext)
  const { exactUrl } = useUrl()

  return (
    <div css={styles()}>

      <div className="content">
        <div className="checkout-success-image-container">
          <img src={CheckoutSuccessImage} alt="Checkout Success" />
        </div>
        <h1 className="title">
          {config.variant === APP_VARIANTS.PPW ? 'Thank you for contributing to our campaign!' : 'Thank you for your contribution!'}
        </h1>
        <p className="paragraph">{config.variant === APP_VARIANTS.PPW ? 'Check your email for more information' : 'Check your email for your ticket numbers and payment confirmation'}</p>
        <div className="buttons-container">
          
          <div className="button-container">
            <Link to={`${exactUrl}/`} className="go-home-button action-button">
              Return Home
            </Link>
          </div>
          {config.variant === APP_VARIANTS.CUE && 
            <div className="button-container">
              <Link
                to={`${exactUrl}/checkout${
                  myGetPricingTierId() ? `?pricing-tier-id=${myGetPricingTierId()}` : ""
                }`}
                className="regular-button"
              >
                Buy More
              </Link>
            </div>
          }
        </div>
      </div>
      {config.variant === APP_VARIANTS.CUE && 
        <div className="disclaimer">
          <div className="disclaimer-content">
            You can cancel any order during 15 minutes after the purchase,
            provided the raffle timer is still up. Check the receipt for more
            information.
          </div>
        </div>
      }
    </div>
  )
}
