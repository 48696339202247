import PropTypes from "prop-types"
import { styles } from "./MenuButton.styles"

export default function MenuButton({ isOpen, onClick }) {
  return (
    <button type="button" css={styles({ isOpen })} onClick={onClick}>
      <span className="menu-button-stick menu-button-stick--top" />
      <span className="menu-button-stick menu-button-stick--middle" />
      <span className="menu-button-stick menu-button-stick--bottom" />
    </button>
  )
}

MenuButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
