import { styles } from "./Input.styles"

export default function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  const inputRef = props.inputRef
  delete props.inputRef

  return (
    <div css={styles({ error: touched[field.name] && errors[field.name] })}>
      {props.label && (
        <label className="label" htmlFor={props.id || props.name}>
          {props.label}
        </label>
      )}
      <input className="input" ref={inputRef} {...field} {...props} />
    </div>
  )
}
