import { useContext } from "react"
import LinkIcon from "assets/icons/link-icon.svg"
import { ConfigContext } from "contexts/ConfigContext"
import { styles } from "./OrganizationSection.styles"
import { APP_VARIANTS } from "config"

export default function OrganizationSection() {
  const {
    config: { variant, charityName, charityDescription, charityUrl, charityImageUrl, sponsorUrl, advertisementText, advertisementUrl, advertisementName },
  } = useContext(ConfigContext)

  const imageUrl = sponsorUrl ? sponsorUrl : variant === APP_VARIANTS.CUE ? charityImageUrl : ''
  const description = advertisementText ? advertisementText : variant === APP_VARIANTS.CUE ? charityDescription : ''
  const learnMoreUrl = advertisementUrl ? advertisementUrl : variant === APP_VARIANTS.CUE ? charityUrl : ''
  const oragnizationName = variant === APP_VARIANTS.PPW ? advertisementName : charityName

  if ((!description && !imageUrl && !oragnizationName && !learnMoreUrl)) {
    return <div />
  }

  return (
    <section css={styles()}>
      <div className="organization-section-content-container">
        <div className="organization-section-content">
          <h2 className="title">{oragnizationName}</h2>
          <p className="paragraph">{description}</p>
          {learnMoreUrl && (
            <div className="organization-link-container">
              <a
                className="organization-link"
                href={learnMoreUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn More <img src={LinkIcon} alt="Link" />
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="organization-section-image-container">
        <div className="organization-outer-image-container">
          <div className="organization-inner-image-container">
          <img
            className="organization-image"
            src={imageUrl}
            alt="Organization"
          />
          </div>
        </div>
      </div>
    </section>
  )

}
