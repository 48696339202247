import React, { useState, useMemo, useCallback, useContext } from "react"
import axios from "axios"
import * as yup from "yup"
import SingleFieldQuestionForm from "components/SingleFieldQuestionForm/SingleFieldQuestionForm"
import Footer from "components/Footer/Footer"
import { ConfigContext } from "contexts/ConfigContext"
import { API_BASE_URL, AM_I_A_WINNER_BASE } from "config"

export default function AmIAWinner() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isWinnerStatus, setIsWinnerStatus] = useState({
    fetched: false,
    fetchFailed: false,
    isWinner: null,
  })
  const { config } = useContext(ConfigContext)

  const handleSubmit = useCallback(
    async (values) => {
      setIsSubmitting(true)

      const url = `${API_BASE_URL}${AM_I_A_WINNER_BASE}${config.eventReference}/${values.ticketNumber}`
      try {
        const { data } = await axios.get(url)

        setIsWinnerStatus({ fetched: true, isWinner: data.winner })
      } catch (e) {
        setIsWinnerStatus((prevStatus) => ({
          ...prevStatus,
          fetchFailed: true,
        }))
      }

      setIsSubmitting(false)
    },
    [config.eventReference]
  )

  const fieldName = "ticketNumber"

  const schema = useMemo(
    () =>
      yup.object().shape({
        [fieldName]: yup.string().required("Required"),
      }),
    [fieldName]
  )

  return (
    <>
      <SingleFieldQuestionForm
        head="Am I A Winner?"
        paragraph="Put in your ticket number"
        fieldName={fieldName}
        fieldPlaceholder="Ticket number"
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        buttonText="Check Results"
        schema={schema}
        showResponseView={isWinnerStatus.fetched}
        responseViewHead={
          isWinnerStatus.isWinner ? "You’re a Winner!" : "Maybe Next Time"
        }
        responseViewParagraph={
          isWinnerStatus.isWinner
            ? ""
            : ""
        }
        errorMessage={
          isWinnerStatus.fetchFailed ? "Fetching winner status failed" : ""
        }
      />
      <Footer />
    </>
  )
}
