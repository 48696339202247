export const breakpoints = {
  mobile: 360,
  mobileLarge: 640,
  tablet: 768,
  desktopSmall: 1024,
  desktopMedium: 1440,
  desktopLarge: 1920,
}

export const mediaQueries = {
  mobileScreen: `@media only screen and (min-width:${breakpoints.mobile}px)`,
  mobileLargeScreen: `@media only screen and (min-width: ${breakpoints.mobileLarge}px)`,
  mobileLandscapeScreen: `@media only screen and (orientation: landscape) and (max-width: ${
    breakpoints.tablet - 1
  }px)`,
  tabletScreen: `@media only screen and (min-width: ${breakpoints.tablet}px)`,
  desktopSmallScreen: `@media only screen and (min-width: ${breakpoints.desktopSmall}px)`,
  desktopMediumScreen: `@media only screen and (min-width: ${breakpoints.desktopMedium}px)`,
  desktopLargeScreen: `@media only screen and (min-width: ${breakpoints.desktopLarge}px)`,
}

export const outerPadding = {
  mobile: "20px",
  tablet: "40px",
  desktop: "30px",
}

export const colors = {
  error: "#F24B4B",
}
