import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`
  margin-top: 80px;

  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
    color: #111319;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
      font-size:24px;
    }
  }

  .faq-link-container {
    margin-top: 20px;
  }
  ${mediaQueries.desktopSmallScreen} {
    .faq-link-container {
      width: 256px;
    }
  }
`
