import { useContext } from "react"
import { ConfigContext } from "contexts/ConfigContext"
import { styles } from "./Legal.styles"

export default function Legal() {
  const {
    config: { legalUrl },
  } = useContext(ConfigContext)

  return (
    <div css={styles()}>
      <object
        data={legalUrl}
        type="application/pdf"
        style={{ width: "100%", height: "100%" }}
      >
        <iframe
          title="Legal PDF"
          src={`https://docs.google.com/viewer?url=${legalUrl}&embedded=true`}
          frameborder="0"
          scrolling="auto"
          style={{ width: "100%", height: "100%" }}
          seamless=""
        ></iframe>
      </object>
    </div>
  )
}
