import { sendPublicLoginLink } from "api/subscriptions"
import { ConfigContext } from "contexts/ConfigContext"
import { useContext, useState } from "react"

const validateEmail = (email) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export const STATUS = {
  idle: "IDLE",
  sendingEmail: "SENDING_EMAIL",
  emailSent: "EMAIL_SENT",
  error: "ERROR",
}

export const useSubscriptions = () => {
  const [email, setEmail] = useState(null)
  const [status, setStatus] = useState(STATUS.idle)
  const { config } = useContext(ConfigContext)

  const submitEmail = async () => {
    setStatus(STATUS.sendingEmail)
    try {
      await sendPublicLoginLink({
        pledgerEmail: email,
        vendorReference: config.vendorIdentifier,
      })
      setStatus(STATUS.emailSent)
    } catch (err) {
      setStatus(STATUS.error)
    }
  }

  const validEmail = validateEmail(email)

  return {
    setEmail,
    validEmail,
    submitEmail,
    email,
    status,
  }
}
