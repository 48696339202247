import { Link } from "react-router-dom"
import useUrl from "hooks/useUrl"
import { styles } from "./Footer.styles"
import { ConfigContext } from "contexts/ConfigContext"
import { APP_VARIANTS } from "config"
import { useContext } from "react"

export default function Footer() {
  const { exactUrl } = useUrl()
  const { config } = useContext(ConfigContext)

  return (
    <footer css={styles()}>
      <div className="company-note">
        Powered by{" "}
        <a
          className="company-link"
          href={config.variant === APP_VARIANTS.PPW ? "https://cueaudio.com/PPW" : "https://cueaudio.com/"}
          rel="noopener noreferrer"
          target="_blank"
        >
          CUE
        </a>
      </div>
      <div className="legal-note">
        {config.variant === APP_VARIANTS.PPW &&
          <Link to={`${exactUrl}/privacy`}>Privacy</Link>
        }
        {config.variant === APP_VARIANTS.CUE &&
          <Link to={`${exactUrl}/legal`}>Rules</Link>
        }
      </div>
    </footer>
  )
}
