import { useContext } from "react"
import { ConfigContext } from "contexts/ConfigContext"

export default function ExpandMinusIcon() {
  const { config } = useContext(ConfigContext)

  return (
    <svg
      style={{ minWidth: '16px' }}
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="16"
        width="4"
        height="16"
        transform="rotate(90 16 0)"
        fill={config.primaryColor}
      />
    </svg>
  )
}
