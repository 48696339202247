import { useContext } from "react"
import Step1Image from "assets/images/step1-how-it-works.svg"
import Step2Image from "assets/images/step2-how-it-works.svg"
import Step3Image from "assets/images/step3-how-it-works.svg"
import { styles } from "./HowItWorksSection.styles"
import { ConfigContext } from "contexts/ConfigContext"
import { APP_VARIANTS } from "config"



export default function HowItWorksSection() {

  const { config } = useContext(ConfigContext)
  const variant = config.variant
  const stepsContainerClassName = variant === APP_VARIANTS.PPW ? 'vertical-steps-container' : 'steps-container'
  const containerStyle = variant === APP_VARIANTS.PPW ? { padding: 0, marginLeft: 20 } : {}

  const stepsData = {
    [APP_VARIANTS.PPW]: [
      {
        imgSrc: Step1Image,
        title: "Step 1",
        paragraph:
          "Choose a team campaign below, determine the amount you would like to donate per qualifying win or stat and MAKE A PLEDGE.",
      },
      {
        imgSrc: Step2Image,
        title: "Step 2",
        paragraph:
          "Follow each event on social medie, live stats, online broadcasts or come out and cheer the on!",
      },
      {
        imgSrc: Step3Image,
        title: "Step 3",
        paragraph:
          "After each event, your pledge will be calculated and charged to your credit card. Your donation makes an impact!",
      },
    ],
    [APP_VARIANTS.CUE]: [
      {
        imgSrc: Step1Image,
        title: "Step 1",
        paragraph:
          "Purchase a ticket by selecting one of the ticket tiers available and continuing to checkout.",
      },
      {
        imgSrc: Step2Image,
        title: "Step 2",
        paragraph:
          "Wait for the winning ticket to be drawn at the date and time specified on this web page.",
      },
      {
        imgSrc: Step3Image,
        title: "Step 3",
        paragraph:
          "If your ticket is drawn as the winner, contact 50/50 staff or support to claim your prize.",
      },
    ]
  }

  const steps = stepsData?.[variant].map(({ imgSrc, title, paragraph }) => {
    if (variant === APP_VARIANTS.PPW) {
      return (
        <div className="step-card" key={title}>
          <h3>{title}</h3>
          <p >{paragraph}</p>
        </div>
      )
    }
    return (
      <div className="step" key={title}>
        <div className="step-image-container">
          <img className="step-image" src={imgSrc} alt="Step 1" />
        </div>
        <h3 className="step-title">{title}</h3>
        <p className="step-paragraph">{paragraph}</p>
      </div>
    )
  })


  return (
    <section css={styles({ config })} style={containerStyle}>
      {variant !== APP_VARIANTS.PPW &&
        <div>
          <h2 className="title">How It Works</h2>
          <p className="paragraph">
            The 50/50 is a fundraising event that allows fans in-venue and at home
            to participate in a raffle benefiting the certified 501-C3 charity. By
            purchasing a raffle ticket, you directly support a local or national
            non-profit while at the same time enter to win a lump-sum cash prize.
          </p>
        </div>
      }
      <div className={stepsContainerClassName}>{steps}</div>
    </section>
  )
}
