import PropTypes from "prop-types"
import ExpandableItem from "components/ExpandableItem/ExpandableItem"
import { styles } from "./ExpandableGroup.styles"

export default function ExpandableGroup({ items }) {
  const expandableItems = items.map(({ head, paragraph }, i) => (
    <div className="item-container" key={head + i}>
      <ExpandableItem head={head} paragraph={paragraph} />
    </div>
  ))

  return <div css={styles()}>{expandableItems}</div>
}

ExpandableGroup.defaultProps = {
  items: [],
}

ExpandableGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}
