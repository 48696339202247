import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`
  height: calc(100vh - 145px);

  ${mediaQueries.tabletScreen} {
    height: calc(100vh - 180px);
  }

  ${mediaQueries.desktopSmallScreen} {
    height: calc(100vh - 115px);
  }
`
