import PropTypes from "prop-types";
import React, { useMemo, useState, useContext } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import ErrorMessageText from "components/ErrorMessageText/ErrorMessageText";
import { PurchaseContext } from "contexts/PurchaseContext";
import VisaLogo from "components/VisaLogo/VisaLogo";
import MasterCardLogo from "components/MasterCardLogo/MasterCardLogo";
import CardInput from "./CardInput/CardInput";
import { styles } from "./CheckoutPaymentMethod.styles";
import { ConfigContext } from "contexts/ConfigContext";
import { APP_VARIANTS } from "config";
import { StateAbbreviations } from "utils/helpers";

const isCreditCardVendorPossible = ({ cardNumberInfo, targetType }) => {
  if (cardNumberInfo.brand === "unknown") {
    return true;
  }

  return cardNumberInfo.brand === targetType;
};

const getFieldSchema = ({ fieldName, validationBase, requiredFields }) => {
  let field = validationBase;
  if (requiredFields[fieldName]) {
    field = field.required("Required");
  }
  return field;
};

const getFieldInitialValue = ({ fieldName, personalInformation }) =>
  personalInformation[fieldName] || "";

export default function CheckoutPaymentMethod({
  ticketText,
  goToNextStep,
  returnStepIndex,
  autoFocusFieldName,
  goToStepByIndex,
  shown,
  togglePurchaseMenu,
}) {
  const {
    paymentMethod,
    setPaymentMethod,
    creditCardType,
    setCreditCardType,
    myGetPricingTierId,
    myGetTeamIndex,
    myGetPersonalInformation,
    mySetPersonalInformation,
  } = useContext(PurchaseContext);

  const { config } = useContext(ConfigContext);
  const { variant } = config;

  const [cardNumberInfo, setCardNumberInfo] = useState({});
  const [cardExpiryInfo, setCardExpiryInfo] = useState({});
  const [cardCvcInfo, setCardCvcInfo] = useState({});

  const stateOptions = StateAbbreviations.map((val) => {
    return { title: val };
  });

  const onTicketReplaceClick = () => {
    togglePurchaseMenu();
  };

  const handleSubmit = (values) => {
    setPaymentMethod({
      ...values,
    });
    setCreditCardType(cardNumberInfo.brand);
    mySetPersonalInformation({ ...myGetPersonalInformation(), ...values });

    if (returnStepIndex != null) {
      goToStepByIndex({ stepIndex: returnStepIndex });
    } else {
      goToNextStep();
    }
  };

  const requiredCueFields = {
    cardZip: true,
  };

  const requiredPPWFields = {
    fullName: true,
    addressLine1: true,
    city: true,
    state: true,
    cardZip: true,
  };

  const requiredFields =
    config.variant === APP_VARIANTS.PPW ? requiredPPWFields : requiredCueFields;

  const cueSchema = useMemo(
    () =>
      yup.object().shape({
        cardZip: getFieldSchema({
          fieldName: "cardZip",
          validationBase: yup
            .string("Please enter valid ZIP Code")
            .min(5, "Zip is too short.")
            .max(10, "Zip is too long."),
          requiredFields,
        }),
      }),
    [requiredFields]
  );

  const ppwSchema = useMemo(
    () =>
      yup.object().shape({
        fullName: getFieldSchema({
          fieldName: "fullName",
          validationBase: yup.string(),
          requiredFields,
        }),
        addressLine1: getFieldSchema({
          fieldName: "addressLine1",
          validationBase: yup.string(),
          requiredFields,
        }),
        addressLine2: getFieldSchema({
          fieldName: "addressLine2",
          validationBase: yup.string(),
          requiredFields,
        }),
        city: getFieldSchema({
          fieldName: "city",
          validationBase: yup.string(),
          requiredFields,
        }),
        state: getFieldSchema({
          fieldName: "state",
          validationBase: yup.string().min(2, "Required"),
          requiredFields,
        }),
        cardZip: getFieldSchema({
          fieldName: "cardZip",
          validationBase: yup.string().min(5, "ZIP is too short"),
          requiredFields,
        }),
      }),
    [requiredFields]
  );

  const ppwInitialValues = {
    fullName: getFieldInitialValue({
      fieldName: "fullName",
      personalInformation: myGetPersonalInformation(),
    }),
    addressLine1: getFieldInitialValue({
      fieldName: "addressLine1",
      personalInformation: myGetPersonalInformation(),
    }),
    addressLine2: getFieldInitialValue({
      fieldName: "addressLine2",
      personalInformation: myGetPersonalInformation(),
    }),
    city: getFieldInitialValue({
      fieldName: "city",
      personalInformation: myGetPersonalInformation(),
    }),
    state: getFieldInitialValue({
      fieldName: "state",
      personalInformation: myGetPersonalInformation(),
    }),
    cardZip: paymentMethod?.cardZip || "",
  };

  const cueInitialValues = {
    cardZip: paymentMethod?.cardZip || "",
  };

  const isAnyRequiredValueEmpty = (values) => {
    let result =
      !cardNumberInfo.complete ||
      !cardExpiryInfo.complete ||
      !cardCvcInfo.complete;

    result =
      result ||
      Object.keys(requiredFields).some(
        (field) => requiredFields[field] && !values[field]
      );

    return result;
  };

  const schema = config.variant === APP_VARIANTS.PPW ? ppwSchema : cueSchema;

  const initialValues =
    config.variant === APP_VARIANTS.PPW ? ppwInitialValues : cueInitialValues;

  const isNumber = (str) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };

  const isNumberInput = (e) => {
    return isNumber(e.target.value.trim()) || !e.target.value;
  };

  return (
    <div css={styles()}>
      <h1 className="head">Payment</h1>
      <div>{ticketText}</div>
      <div className="form-container">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnChange
        >
          {({ errors, values, setFieldValue }) => (
            <Form>
              <div className="credit-card-logos">
                <div className="credit-card-logo-container">
                  <VisaLogo
                    grayed={
                      cardNumberInfo
                        ? !isCreditCardVendorPossible({
                            cardNumberInfo,
                            targetType: "visa",
                          })
                        : creditCardType === "mastercard"
                    }
                  />
                </div>
                <div className="credit-card-logo-container">
                  <MasterCardLogo
                    grayed={
                      cardNumberInfo
                        ? !isCreditCardVendorPossible({
                            cardNumberInfo,
                            targetType: "mastercard",
                          })
                        : creditCardType === "visa"
                    }
                  />
                </div>
              </div>
              <div className="field-container">
                <div className="card-number-label">Card number</div>
                <div className="card-number-container">
                  <CardInput
                    Component={CardNumberElement}
                    id="card-number"
                    onChange={setCardNumberInfo}
                    placeholder="XXXX XXXX XXXX XXXX"
                    cardInputInfo={cardNumberInfo}
                    autoFocus={autoFocusFieldName === "cardNumber" || shown}
                  />
                </div>
                <div className="card-additional-info-container">
                  <div className="card-expiry-container">
                    <CardInput
                      Component={CardExpiryElement}
                      id="card-expiry"
                      onChange={setCardExpiryInfo}
                      placeholder="MM / YY"
                      textAlign="center"
                      cardInputInfo={cardExpiryInfo}
                    />
                  </div>
                  <div className="card-cvv-container">
                    <div className="card-cvv-disclaimer-container">
                      Digits on the back
                      <br />
                      of the card
                    </div>
                    <div className="card-cvv-input-container">
                      <CardInput
                        Component={CardCvcElement}
                        id="card-cvc"
                        onChange={setCardCvcInfo}
                        placeholder="CVV"
                        textAlign="center"
                        cardInputInfo={cardCvcInfo}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {variant === APP_VARIANTS.PPW && (
                <>
                  <div className="field-container">
                    <Field
                      name="fullName"
                      type="text"
                      label="Name On Card"
                      placeholder="Name On Card"
                      autoComplete="givenName"
                      component={Input}
                      onKeyUp={(e) => {
                        setFieldValue("fullName", e.target.value);
                        mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                      }}
                    />
                    <ErrorMessage
                      name="fullName"
                      className="field-error-message-container"
                      component={ErrorMessageText}
                    />
                  </div>
                  <div className="field-container">
                    <Field
                      name="addressLine1"
                      type="text"
                      label="Address Line 1"
                      placeholder="Address Line 1"
                      autoComplete="address"
                      component={Input}
                      onKeyUp={(e) => {
                        setFieldValue("addressLine1", e.target.value);
                        mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                      }}
                    />
                    <ErrorMessage
                      name="addressLine1"
                      className="field-error-message-container"
                      component={ErrorMessageText}
                    />
                  </div>
                  <div className="field-container">
                    <Field
                      name="addressLine2"
                      type="text"
                      label="Address Line 2"
                      placeholder="Address Line 2"
                      autoComplete="address"
                      component={Input}
                      onKeyUp={(e) => {
                        setFieldValue("addressLine2", e.target.value);
                        mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                      }}
                    />
                    <ErrorMessage
                      name="addressLine2"
                      className="field-error-message-container"
                      component={ErrorMessageText}
                    />
                  </div>
                  <div className="field-container">
                    <Field
                      name="city"
                      type="text"
                      label="City"
                      placeholder="City"
                      autoComplete="city"
                      component={Input}
                      onKeyUp={(e) => {
                        setFieldValue("city", e.target.value);
                        mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                      }}
                    />
                    <ErrorMessage
                      name="city"
                      className="field-error-message-container"
                      component={ErrorMessageText}
                    />
                  </div>
                  <div className="field-container">
                    <Field
                      name="state"
                      type="select"
                      label="State"
                      placeholder={{ title: "Select State", value: "" }}
                      autoComplete="state"
                      options={stateOptions}
                      component={Select}
                      onKeyUp={(e) => {
                        setFieldValue("state", e.target.value);
                        mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                      }}
                    />
                    <ErrorMessage
                      name="state"
                      className="field-error-message-container"
                      component={ErrorMessageText}
                    />
                  </div>
                </>
              )}
              <div className="field-container">
                <Field
                  name="cardZip"
                  type="text"
                  label="ZIP Code"
                  placeholder="ZIP Code associated with the card"
                  autoComplete="postal-code"
                  component={Input}
                  onChange={(e) => {
                    if (!isNumberInput(e)) {
                      return;
                    }
                    setFieldValue("cardZip", e.target.value.trim());
                  }}
                />
                <ErrorMessage
                  name="cardZip"
                  className="field-error-message-container"
                  component={ErrorMessageText}
                />
              </div>
              <div className="submit-button-container">
                <button
                  style={{ width: "100%", marginRight: "10px" }}
                  className="action-button back"
                  type="button"
                  onClick={() =>
                    goToStepByIndex({
                      stepIndex: 0,
                      autoFocusFieldName: "firstName",
                      returnStepIndex: 1,
                    })
                  }
                >
                  Back
                </button>
                <button
                  className="action-button mobile-widths"
                  type="submit"
                  disabled={
                    Object.keys(errors).length ||
                    isAnyRequiredValueEmpty(values)
                  }
                >
                  Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
CheckoutPaymentMethod.propTypes = {
  goToNextStep: PropTypes.func,
  autoFocusFieldName: PropTypes.string,
  returnStepIndex: PropTypes.number,
  shown: PropTypes.bool,
  goToStepByIndex: PropTypes.func,
};

CheckoutPaymentMethod.defaultProps = {
  goToNextStep: () => {},
  autoFocusFieldName: "",
  shown: false,
  goToStepByIndex: () => {},
};
