import { css } from "@emotion/react"

export const styles = () => css`
  .label {
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #898b93;
    margin-bottom: 4px;
  }

  .value-container {
    display: flex;
    justify-content: space-between;
  }

  .value-container {
    flex-grow: 1;
  }

  .value-content {
    display: flex;
  }

  .icon-container {
    flex-basis: 38px;
    margin-right: 10px;
    flex-grow: 0;
    display: flex;

    svg {
      width: 100%;
    }
  }

  .value {
    font-size: 16px;
    line-height: 140%;
    color: #111319;
    flex: 1;
    display: flex;
    align-items: center;
  }

  .change-button {
    padding: 0;
    flex-grow: 0;
    background-color: transparent;
    border: none;
    font-size: 16px;
    line-height: 140%;
    color: #898b93;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
    }
  }
`
