import PropTypes from "prop-types"

export default function VisaLogo({ grayed }) {
  return (
    <svg viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.52 0C0.680694 0 0 0.684382 0 1.52866V22.4713C0 23.3156 0.681036 24 1.52 24H36.48C37.3193 24 38 23.3156 38 22.4713V1.52866C38 0.68442 37.319 0 36.48 0H1.52ZM22.6955 7.15246C23.6203 7.15246 24.3615 7.35791 24.8342 7.54896L24.5112 9.59236L24.2974 9.48367C23.8571 9.29262 23.2916 9.10876 22.5114 9.1218C21.5775 9.1218 21.1458 9.54076 21.1458 9.93275C21.1404 10.3744 21.6503 10.6657 22.4841 11.1019C23.8604 11.7749 24.4964 12.5909 24.4875 13.6636C24.4689 15.6209 22.8412 16.8858 20.3336 16.8858C19.2638 16.8739 18.2331 16.6461 17.6759 16.3829L18.0108 14.2715L18.3184 14.422C19.1018 14.774 19.6091 14.9164 20.5639 14.9164C21.2496 14.9164 21.9855 14.6277 21.9913 13.9956C21.9958 13.5829 21.6837 13.2886 20.7551 12.8264C19.8503 12.3753 18.6507 11.6197 18.664 10.2647C18.6781 8.43183 20.3384 7.15246 22.6955 7.15246ZM3.36061 7.45223H7.21168C7.73026 7.47195 8.14895 7.63987 8.2935 8.20582L9.12357 12.5147C9.12369 12.5151 9.12342 12.5167 9.12357 12.5171L9.37293 13.8081L11.7111 7.45223H14.2405L10.4809 16.7556L7.95386 16.758L5.94225 9.25078C7.13917 9.8885 8.15818 10.6263 8.74832 11.6417C8.59617 11.3204 8.39568 10.9578 8.13914 10.6003C7.84039 10.1841 7.19519 9.64662 6.9255 9.41798C5.98576 8.62127 4.70953 7.97782 3.33093 7.63735L3.36061 7.45223ZM15.2463 7.46297H17.7199L16.1725 16.752H13.699L15.2463 7.46297ZM29.2172 7.46297H31.0911L33.0529 16.752H30.8037C30.8037 16.752 30.5806 15.6848 30.5081 15.3595C30.1546 15.3595 27.6821 15.3559 27.4039 15.3559C27.3098 15.6074 26.8933 16.752 26.8933 16.752H24.3485L27.9478 8.23448C28.2026 7.62921 28.637 7.46297 29.2172 7.46297ZM29.4013 9.96138C29.2796 10.3069 29.0677 10.8649 29.0819 10.8404C29.0819 10.8404 28.3204 12.8826 28.1212 13.4128L30.1221 13.4116C30.0257 12.951 29.564 10.7544 29.564 10.7544L29.4013 9.96145V9.96138Z"
        fill={grayed ? "#E0E3EB" : "#243880"}
      />
    </svg>
  )
}

VisaLogo.defaultProps = {
  grayed: false,
}

VisaLogo.propTypes = {
  grayed: PropTypes.bool,
}
