import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ isExpanded }) => css`
  background-color: #fbfcff;
  border: 1px solid #eceef7;
  padding-bottom: 4px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f5f7ff;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 1px 2px #0d64d8;
  }

  .head {
    padding: 16px 16px 14px;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: #111319;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    img {
      margin-left: 20px;
    }
  }

  .paragraph {
    padding: 0 20px 16px 20px;
    display: ${isExpanded ? "block" : "none"};
    transition: 0.25s ease-in-out;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.5px;
    color: #111319;
    text-align: left;
  }

  ${mediaQueries.mobileLargeScreen} {
    .head-text {
      padding-right: 10%;
    }

    .paragraph {
      padding-right: 30%;
    }
  }
`
