import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ isOpen, align, alterCheckout, config }) => css`
  .menu-button {
    z-index: 200;
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    background-color: #313339;
  }

  .purchase-content {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #111319;
    z-index: 201;
    right: ${isOpen ? "0" : `${align === "right" ? "-" : ""}100%`};
    top: 0;
    transition: 0.25s ease-in-out;
    margin-right: ${isOpen || (align === 'right' && !isOpen) ? '-17px' : '0'};
    overflow-y: scroll;
    width: calc(100% + 25px);
  }

  .purchase-content-text {
    padding: 24px 20px 20px;
  }

  .back-button-container {
    margin-bottom: 30px;
  }

  .icon-container {
    margin-bottom: 20px;
    img {
      width: 49px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
    color: #ffffff;
  }

  .no-tickets-available {
    color: #ffffff;
    margin-top: 20px;
  }

  .tickets-container {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .ticket-item {
    padding: 2px 4px;
    width: 100%;
  }

  .ticket-button {
    width: 100%;
    height: 100%;
    background-color: #313339;
    padding: 28px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.5px;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background-color: #45474d;
    }
  }

  .ticket-item--selected .ticket-button {
    background-color: ${config.primaryColor};
  }

  ${mediaQueries.tabletScreen} {
    .menu-button {
      bottom: 20px;
      left: 20px;
      right: 20px;
      width: calc(100% - 40px);
    }

    .purchase-content-text {
      padding: 40px 40px 30px;
    }

    .ticket-button {
      padding: 64px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    .purchase-content {
      width: 256px;
      top: 0;
      right: ${align === "right" ? 0 : "auto"};
      left: ${align === "left" ? 0 : "auto"};
      overflow-x: auto;
      margin-right: ${align === "right" ? 0 : "auto"}
    }

    .purchase-content-text {
      padding: 30px 20px;
    }

    .menu-button {
      display: none;
    }

    .back-button-container {
      ${alterCheckout ? "" : "display: none;"}
    }

    .tickets-container {
      position: initial;
    }

    .ticket-button {
      padding-top: 44px;
      padding-bottom: 44px;
    }
  }

  ${mediaQueries.desktopMediumScreen} {
    .purchase-content {
      width: 360px;
    }

    .ticket-button {
      padding-top: 44px;
      padding-bottom: 44px;
    }

    .purchase-content-text {
      padding: 30px;
    }
  }

  ${mediaQueries.desktopLargeScreen} {
    .purchase-content {
      width: 480px;
    }

    .ticket-button {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }
`
