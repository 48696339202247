import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ config }) => css`

  margin-top:80px;

  .title {
    color: #111319;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }

  .paragraph {
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 30px;
    color: #111319;
  }

  .step {
    margin-bottom: 40px;
    flex: 1;
  }

  .step-image-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .step-image {
    overflow: hidden;
    //width: 100%;
    background-color: ${config.primaryColor}; 
    // height: 320px;
    object-fit: cover;
    object-position: center;
  }

  .step-title {
    color: #111319;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.5px;
    margin-bottom: 10px;
  }

  .step-paragraph {
    font-size: 16px;
    line-height: 140%;
    color: #111319;
  }

  // for ppw website

  .vertical-steps-container {
    display:flex;
    width: 100%;
    flex:1;
    flex-direction: column;
    height:100%;
    justify-content: space-between;
  }

  .step-card {
    background-color: #333333;
    border-radius: 10px;
    padding: 30px;
    height: 31.5%;

    h3{
      color: #FFFFFF;
      text-align: center;
      margin-bottom: 25px;
    }

    p{
      color: #FFFFFF;
      text-align: center;
      font-size: 11pt;
    }
  }

  ${mediaQueries.mobileLargeScreen} {
    .paragraph {
      margin-bottom: 40px;
      width: 67%;
    }

    .steps-container {
      display: flex;
      margin-left: -5px;
      margin-right: -5px;
    }

    .step {
      padding-left: 5px;
      padding-right: 5px;
    }

    .step-image-container {
      // padding-top: 134%;
      position: relative;
      display: flex;
      justify-content: center;
    }

    .step-image {
      // position: absolute;
      //width: 100%;
      //left: 0;
      //top: 0;
      
      // height: 100%;
    }
  }

  ${mediaQueries.tabletScreen} {
    .steps-container {
      margin-left: -10px;
      margin-right: -10px;
    }

    .step {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {  
    .paragraph {
      width: 50%;
    }

    .steps-container{
      margin-top: 100px;
    }
  }

  ${mediaQueries.desktopMediumScreen} {
    padding-left: 100px;
    padding-right: 100px;

    .paragraph {
      margin-bottom: 30px;
    }
  }

  ${mediaQueries.desktopLargeScreen} {
    padding-left: 130px;
    padding-right: 130px;
  }
`
