import { useContext, useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { ConfigContext } from "contexts/ConfigContext"
import dayjs from "utils/dayjs"
import { styles } from "./RafflePot.styles"

function ensureTwoDigitNumber(n) {
  return (n < 10 ? "0" : "") + n
}

function presentTimeUnit(time) {
  return isNaN(time) ? "--" : time
}

export default function RafflePot({ notStarted }) {
  const { config } = useContext(ConfigContext)

  const eventStartCalendar = dayjs(config.realStartDateTime).calendar(dayjs())
  const eventEndDateCalendar = dayjs(config.realEndDateTime).format('MM/DD/YYYY')
  const eventEndTime = config.winnerSelectTime

  const isStartDateTimeValid = eventStartCalendar !== "Invalid Date"

  const calculateTimeRemainingTillStart = useCallback(() => {
    const date = new Date(config.realStartDateTime).getTime()
    const now = new Date().getTime()
    const distance = date - now
    if (distance <= 0) {
      return { hours: "00", minutes: "00", seconds: "00", finished: true }
    }
    const hours = ensureTwoDigitNumber(Math.floor(distance / (1000 * 60 * 60)))
    const minutes = ensureTwoDigitNumber(
      Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    )
    const seconds = ensureTwoDigitNumber(
      Math.floor((distance % (1000 * 60)) / 1000)
    )
    return { hours, minutes, seconds }
  }, [config.realStartDateTime])

  const [timeRemainingTillStart, setTimeRemainingTillStart] = useState(
    calculateTimeRemainingTillStart()
  )

  useEffect(() => {
    if (timeRemainingTillStart.finished) {
      return
    }
    const interval = setInterval(() => {
      setTimeRemainingTillStart(calculateTimeRemainingTillStart())
      if (timeRemainingTillStart.finished) {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [calculateTimeRemainingTillStart, timeRemainingTillStart.finished])

  const { potSize } = config

  return (
    <div css={styles({ config })}>
      <div className="raffle-pot-size-container">
        <h3 className="raffle-pot-title">Raffle pot size</h3>
        {notStarted ? (
          <div className="raffle-pot-size-not-started">Stay Tuned</div>
        ) : (
          <div className="raffle-pot-status">
            <div className="raffle-pot-announcement">
              The winner will be announced{" "}
              <span className="raffle-pot-day">{eventEndDateCalendar} at {eventEndTime}</span>
            </div>
            <div className="raffle-pot-amount">${Math.floor(potSize/100).toLocaleString()}</div>
            <div className="raffle-pot-amount-text">Total</div>
          </div>
        )}
      </div>
      {!timeRemainingTillStart.finished && <div className="event-start-container">
        <h3 className="event-start-title">Time until event starts</h3>
        {!isStartDateTimeValid ? (
          <div className="event-start-not-started">Soon</div>
        ) : (
          <div className="event-start-status">
            <div className="event-start-date">
              Event Starts: {eventStartCalendar}
            </div>
            <div className="event-start-timer">
              <div className="event-start-item">
                <div className="event-start-item-count">
                  {presentTimeUnit(timeRemainingTillStart.hours)}
                </div>
                <div className="event-start-item-name">Hours</div>
              </div>
              <div className="event-start-item">
                <div className="event-start-item-count">
                  {presentTimeUnit(timeRemainingTillStart.minutes)}
                </div>
                <div className="event-start-item-name">Minutes</div>
              </div>
              <div className="event-start-item">
                <div className="event-start-item-count">
                  {presentTimeUnit(timeRemainingTillStart.seconds)}
                </div>
                <div className="event-start-item-name">Seconds</div>
              </div>
            </div>
          </div>
        )}
      </div>}
    </div>
  )
}

RafflePot.defaultProps = {
  notStarted: false,
}

RafflePot.propTypes = {
  notStarted: PropTypes.bool,
}
