import PropTypes from "prop-types"
import { styles } from "./EventsTable.styles"
import dayjs from "utils/dayjs"

export default function EventsTable({ colNames, colWidths, data }) {
  const noWidthsProvided = !colWidths.length

  const getColWidth = (index) => noWidthsProvided ? `${100 / colNames.length}%` : colWidths[index]

  if (!Array.isArray(colNames)) {
    return <div></div>
  }

  const head = colNames?.map((colName, i) => (
    <div className="head-cell" style={{ width: getColWidth(i) }} key={colName}>
      {colName}
    </div>
  ))

  const records = data.map((event, index) => (
    <div className="record" key={index}>
      <div className="record-cell" style={{ width: getColWidth(0) }}>
        <div className="record-cell-content-container">
          <div className="record-cell-value">{event.name}</div>
        </div>
      </div>
      <div className="record-cell" style={{ width: getColWidth(1) }}>
        <div className="record-cell-content-container">
          <div className="record-cell-value">{dayjs(event.dateCompleted)?.format('MM/DD/YY')}</div>
        </div>
      </div>
      <div className="record-cell" style={{ width: getColWidth(2) }}>
        <div className="record-cell-content-container">
          <div className="record-cell-value">Pledge Per {event?.statistic}</div>
        </div>
      </div>
      <div className="record-cell" style={{ width: getColWidth(3) }}>
        <div className="record-cell-content-container">
          <div className="record-cell-value">{event.chargeCount}</div>
        </div>
      </div>
    </div>
  ))

  return (
    <div css={styles()}>
      <div className="table">
        <div className="head">
          <div className="head-row">{head}</div>
        </div>
        <div className="body">{records}</div>
      </div>
    </div>
  )
}

EventsTable.defaultProps = {
  colNames: [],
  colWidths: [],
  data: [],
}

EventsTable.propTypes = {
  colNames: PropTypes.arrayOf(PropTypes.string),
  colWidths: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
}
