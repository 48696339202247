import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import dayjs from "utils/dayjs"
import { ConfigContext } from "contexts/ConfigContext"
import { styles } from "./Pot.styles"

const SECOND = 1000

export default function Pot() {
  const { config } = useContext(ConfigContext)
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const bg = query.get("bg")
  const text = query.get("text")

  const { fetchConfig } = config

  const eventEndDateCalendar = dayjs(config.realEndDateTime).format(
    "MM/DD/YYYY"
  )
  const eventEndTime = config.winnerSelectTime

  useEffect(() => {
    const interval = setInterval(() => {
      fetchConfig()
    }, 30 * SECOND)
    return () => clearInterval(interval)
  }, [fetchConfig])

  return (
    <div css={styles({ bg, text })}>
      <div className="head-container">
        <h2 className="head-text">RAFFLE POT SIZE</h2>
        <div className="head-logo-container">
          <img className="head-logo" src={config.logoUrl} alt="Logo" />
        </div>
      </div>
      <h1 className="pot-size">
        ${Math.floor(config.potSize / 100).toLocaleString()}
      </h1>
      <div className="pot-announcement">
        The winner will be announced{" "}
        <span className="pot-day">
          {eventEndDateCalendar} at {eventEndTime}
        </span>
      </div>
    </div>
  )
}
