import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`
  padding-top: 80px;
  padding-bottom: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .company-note {
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .company-link {
    text-decoration: underline;
    color: inherit;
  }

  .legal-note {
    a {
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.5px;
      color: #111319;
    }
  }

  ${mediaQueries.tabletScreen} {
    .company-note {
      font-size: 18px;
    }

    .legal-note {
      a {
        font-size: 18px;
      }
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    padding-top: 120px;
    padding-bottom: 8px;
  }
`
