import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`
  margin-top: 80px;
  padding:0!important;

  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
    color: #111319;
    margin-bottom: 20px;
  }

  .paragraph {
    font-size: 16px;
    line-height: 140%;
    color: #111319;
    margin-bottom: 20px;
  }

  .organization-link-container {
    margin-bottom: 36px;
    text-align: right;
  }

  .organization-link {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.5px;
    color: #111319;
    text-decoration: none;
    display: inline-flex;

    img {
      margin-left: 10px;
      width: 14px;
    }
  }

  .organization-outer-image-container {
    width: 100%;
    padding-top: 30%;
    position: relative;
  }

  .organization-inner-image-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .organization-image {
    width: 100%;
    height: 100%;
    max-width: 1080px;
    max-height: 318px;
  }

  ${mediaQueries.mobileLargeScreen} {
    display: flex;

    .organization-section-image-container {
      flex: 1;
      flex-grow: 2;
    }

    .organization-section-content-container {
      flex: 1;
    }

    .organization-section-content {
      padding-right: 20px;
    }
  }

  ${mediaQueries.tabletScreen} {
    padding-left: 60px;
    padding-right: 60px;

    .organization-section-content {
      padding-right: 30px;
    }
  }

  ${mediaQueries.desktopMediumScreen} {

    .organization-section-content {
      padding-right: 40px;
    }
  }

  ${mediaQueries.desktopLargeScreen} {
    padding-left: 130px;
    padding-right: 130px;

    .organization-section-content {
      padding-right: 120px;
    }

  }

`
