import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({config}) => css`

  .checkout-step-container {
    min-height: 100vh;
    padding-top: 61px;
    padding-bottom: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .title-container {
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top: 30px;
  }

  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
    color: black;
  }

  .description-container {
    padding: 20px;
    background: ${config.secondaryColor};
    border-radius: 10px;
    margin-bottom: 45px;
  }

  .description {
    font-size: 16px;
    letter-spacing: normal;
    font-weight: 600;
    line-height: 110%;
    color: #ffffff;
    white-space: pre-wrap;
  }

  .ticket-selected-info {
    background-color: #eceef7;
    border: none;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &:hover {
      .ticket-replace {
        text-decoration: underline;
      }
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
    }
  }

  .ticket-selected-info-mobile {
    cursor: pointer;
  }

  .ticket-selected-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .ticket-selected-info-desktop {
    display: none;
  }

  .ticket-replace {
    font-size: 16px;
    line-height: 140%;
    color: #111319;
  }

  .back {
    display: none;
  }

  ${mediaQueries.tabletScreen} {
    .checkout-step-container {
      padding-left: 114px;
      padding-right: 114px;
    }

    .ticket-selected-name {
      font-size: 24px;
    }

    .title-container {
      margin: 0 -5rem;
      margin-bottom: 15px;
      margin-top: 30px;
    }    

    .description-container {
      margin: 0 -4rem;
      margin-bottom: 45px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    .checkout-step-container {
      padding-top: 62px;
      padding-bottom: 0;
    }

    .checkout-step-content {
      max-width: 540px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    
    .ticket-selected-info {
      justify-content: space-around;
    }

    .ticket-replace {
      display: none;
    }

    .ticket-selected-info-desktop {
      display: flex;
    }

    .ticket-selected-info-mobile {
      display: none;
    }

    .back {
      display: block;
    }

    .title-container {
      margin-top: 0;
    } 
  }
`
