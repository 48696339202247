import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`
  width: 100%;

  .head {
    display: none;
  }

  .body {
    display: block;
  }

  .record {
    display: block;
    padding: 20px 16px;
    background-color: #fbfcff;
    border-radius: 10px;
    border: 1px solid #eceef7;
    overflow: hidden;
    margin-top: 4px;
  }

  .record-cell {
    display: block;
    margin-top: 16px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .record-cell-name {
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #aaacb4;
  }

  .record-cell-value {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  ${mediaQueries.mobileLargeScreen} {
    .head {
      display: block;
    }

    .head-row {
      display: block;
      padding: 10px 20px;
      background-color: #fbfcff;
      border-radius: 10px;
      border: 1px solid #eceef7;
      overflow: hidden;
      margin-top: 4px;
    }

    .head-cell {
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.5px;
      color: #aaacb4;
      display: block;
    }

    .record-cell-name {
      display: none;
    }

    .record {
      padding: 22px 20px;
    }

    .record-cell {
      margin-top: 0;
    }

    .head-row,
    .record {
      display: flex;
    }

    .record-cell,
    .head-cell {
      text-align: left;
    }
  }
`
