import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`
  padding-top: 32px;

  .head {
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    letter-spacing: -2px;
    color: #111319;
    margin-bottom: 10px;
  }

  .paragraph {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.5px;
    color: #111319;
    margin-bottom: 20px;
  }

  .submit-button-animation {
    width: 55px;
  }

  .field-container {
    margin-bottom: 10px;
  }

  .field-error-message-container {
    margin-top: 4px;
  }

  .submit-button-container {
    margin-top: 10px;
  }

  .error-message {
    color: #F24B4B;
    text-align: center;
    margin-top: 12px;
  }

  .success-message {
    color: #111319;
    text-align: center;
    margin-top: 12px;
  }

  ${mediaQueries.tabletScreen} {
    padding-top: 64px;
    width: 540px;
    margin: 0 auto;

    .head {
      font-size: 76px;
      line-height: 100%;
      letter-spacing: -2px;
      margin-bottom: 20px;
    }

    .paragraph {
      font-size: 18px;
      line-height: 130%;
      letter-spacing: -0.5px;
      margin-bottom: 30px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    padding-top: 70px;
  }
`
