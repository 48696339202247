import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Input from "components/Input/Input";
import dayjs from "utils/dayjs";
import ErrorMessageText from "components/ErrorMessageText/ErrorMessageText";
import { PurchaseContext } from "contexts/PurchaseContext";
import { ConfigContext } from "contexts/ConfigContext";
import { styles } from "./CheckoutPersonalInformation.styles";
import { APP_VARIANTS } from "config";

const fieldNames = {
  FIRST: "firstName",
  LAST: "lastName",
  PHONE: "phoneNumber",
  EMAIL: "email",
  DOB: "dateOfBirth",
};

const mapFieldsRequired = (lineItems) =>
  lineItems.reduce((prev, cur) => {
    prev[fieldNames[cur.name]] = cur.enabled;
    return prev;
  }, {});

const getFieldSchema = ({ fieldName, validationBase, fieldsRequire }) => {
  let field = validationBase;
  if (fieldsRequire[fieldName]) {
    field = field.required("Required");
  }
  return field;
};

const getFieldLabel = ({ name, isRequired }) =>
  `${name}${isRequired ? "" : " (optional)"}`;

const getFieldInitialValue = ({ fieldName, personalInformation }) =>
  personalInformation[fieldName] || "";

export default function CheckoutPersonalInformation({
  ticketText,
  goToNextStep,
  autoFocusFieldName,
  returnStepIndex,
  goToStepByIndex,
  togglePurchaseMenu,
}) {
  const inputRefs = {
    firstName: useRef(null),
    lastName: useRef(null),
    email: useRef(null),
    phoneNumber: useRef(null),
    dateOfBirth: useRef(null),
  };

  const {
    myGetPersonalInformation,
    mySetPersonalInformation,
    myGetPricingTierId,
    myGetTeamIndex,
  } = useContext(PurchaseContext);
  const { config } = useContext(ConfigContext);

  const { receiptInfo, variant } = config;

  const rawLineItems = receiptInfo?.lineItems || [];
  const lineItems = rawLineItems.filter(
    ({ name }) => name !== "DATE" && name !== "TIME"
  );

  const requiredPPWFields = {
    firstName: true,
    lastName: true,
    email: true,
    phoneNumber: true,
  };
  const fieldsRequire =
    variant === APP_VARIANTS.PPW
      ? requiredPPWFields
      : mapFieldsRequired(lineItems);

  const onTicketReplaceClick = () => {
    togglePurchaseMenu();
  };

  const cueSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: getFieldSchema({
          fieldName: "firstName",
          validationBase: yup.string().max(30, "First name is too long"),
          fieldsRequire,
        }),
        lastName: getFieldSchema({
          fieldName: "lastName",
          validationBase: yup.string().max(30, "Last name is too long"),
          fieldsRequire,
        }),
        email: getFieldSchema({
          fieldName: "email",
          validationBase: yup.string().trim().email("Wrong email format.").max(40, "Email is too long"),
          fieldsRequire,
        }),
        phoneNumber: getFieldSchema({
          fieldName: "phoneNumber",
          validationBase: yup.string().trim().min(10, "Phone number is too short").max(30, "Phone number is too long"),
          fieldsRequire,
        }),
        dateOfBirth: getFieldSchema({
          fieldName: "dateOfBirth",
          validationBase: yup.string().min(3, "Phone number is too short"),
          fieldsRequire,
        }),
      }),
    [fieldsRequire]
  );

  const ppwSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: getFieldSchema({
          fieldName: "firstName",
          validationBase: yup.string(),
          fieldsRequire,
        }),
        lastName: getFieldSchema({
          fieldName: "lastName",
          validationBase: yup.string(),
          fieldsRequire,
        }),
        email: getFieldSchema({
          fieldName: "email",
          validationBase: yup.string().trim().email("Wrong email format"),
          fieldsRequire,
        }),
        phoneNumber: getFieldSchema({
          fieldName: "phoneNumber",
          validationBase: yup
            .string()
            .trim()
            .min(10, "Phone number is too short"),
          fieldsRequire,
        }),
      }),
    [fieldsRequire]
  );

  const schema = variant === APP_VARIANTS.PPW ? ppwSchema : cueSchema;

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    mySetPersonalInformation({ ...myGetPersonalInformation(), ...values });

    if (returnStepIndex != null) {
      goToStepByIndex({ stepIndex: returnStepIndex });
    } else {
      goToNextStep();
    }

    setSubmitting(false);
  };

  useEffect(() => {
    if (autoFocusFieldName) {
      inputRefs[autoFocusFieldName]?.current?.focus();
    }
  }, [autoFocusFieldName, inputRefs]);

  const initialValues = {
    firstName: getFieldInitialValue({
      fieldName: "firstName",
      personalInformation: myGetPersonalInformation(),
    }),
    lastName: getFieldInitialValue({
      fieldName: "lastName",
      personalInformation: myGetPersonalInformation(),
    }),
    phoneNumber: getFieldInitialValue({
      fieldName: "phoneNumber",
      personalInformation: myGetPersonalInformation(),
    }),
    email: getFieldInitialValue({
      fieldName: "email",
      personalInformation: myGetPersonalInformation(),
    }),
    dateOfBirth: getFieldInitialValue({
      fieldName: "dateOfBirth",
      personalInformation: myGetPersonalInformation(),
    }),
  };

  const todayDate = dayjs().format("YYYY-MM-DD");

  const isAnyRequiredValueEmpty = (values) =>
    Object.keys(fieldsRequire).some(
      (field) => fieldsRequire[field] && !values[field]
    );

  const isNumber = (str) => {
    if (str.trim() === "") {
      return false;
    }

    return !isNaN(str);
  };

  const isNumberInput = (e) => {
    return isNumber(e.target.value.trim()) || !e.target.value;
  };

  return (
    <div css={styles()}>
      <h1 className="head">Personal information</h1>
      <div>{ticketText}</div>
      <div className="form-container">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnChange
        >
          {({ isSubmitting, errors, values, setFieldValue }) => (
            <Form>
              <div className="field-container">
                <Field
                  name="firstName"
                  type="text"
                  label={getFieldLabel({
                    name: "First Name",
                    isRequired: true,
                  })}
                  placeholder="First name"
                  autoComplete="given-name"
                  component={Input}
                  inputRef={inputRefs.firstName}
                  autoFocus={autoFocusFieldName === "firstName"}
                  onKeyUp={(e) => {
                    setFieldValue("firstName", e.target.value);
                    mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                  }}
                />
                <ErrorMessage
                  name="firstName"
                  className="field-error-message-container"
                  component={ErrorMessageText}
                />
              </div>
              <div className="field-container">
                <Field
                  name="lastName"
                  type="text"
                  label={getFieldLabel({
                    name: "Last Name",
                    isRequired: true,
                  })}
                  placeholder="Last name"
                  autoComplete="family-name"
                  component={Input}
                  inputRef={inputRefs.lastName}
                  autoFocus={autoFocusFieldName === "lastName"}
                  onKeyUp={(e) => {
                    setFieldValue("lastName", e.target.value);
                    mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                  }}
                />
                <ErrorMessage
                  name="lastName"
                  className="field-error-message-container"
                  component={ErrorMessageText}
                />
              </div>
              <div className="field-container">
                <Field
                  name="email"
                  type="text"
                  label={getFieldLabel({
                    name: "Email",
                    isRequired: true,
                  })}
                  placeholder="Email"
                  autoComplete="email"
                  component={Input}
                  inputRef={inputRefs.email}
                  autoFocus={autoFocusFieldName === "email"}
                  onKeyUp={(e) => {
                    setFieldValue("email", e.target.value.trim());
                    mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                  }}
                />
                <ErrorMessage
                  name="email"
                  className="field-error-message-container"
                  component={ErrorMessageText}
                />
              </div>
              <div className="field-container">
                <Field
                  name="phoneNumber"
                  type="tel"
                  label={getFieldLabel({
                    name: "Phone Number",
                    isRequired: fieldsRequire["phoneNumber"],
                  })}
                  placeholder="e.g. (704) 555-0127"
                  autoComplete="phoneNumber"
                  component={Input}
                  inputRef={inputRefs.phoneNumber}
                  autoFocus={autoFocusFieldName === "phoneNumber"}
                  onKeyUp={(e) => {
                    if (!isNumberInput(e)) {
                      return;
                    }
                    setFieldValue("phoneNumber", e.target.value.trim());
                    mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                  }}
                />
                <ErrorMessage
                  name="phoneNumber"
                  className="field-error-message-container"
                  component={ErrorMessageText}
                />
              </div>
              {variant === APP_VARIANTS.ppw && (
                <div className="field-container">
                  <Field
                    name="dateOfBirth"
                    type="date"
                    max={todayDate}
                    label={getFieldLabel({
                      name: "Date of Birth",
                      isRequired: fieldsRequire["dateOfBirth"],
                    })}
                    placeholder="MM/DD/YYYY"
                    autoComplete="bday"
                    component={Input}
                    inputRef={inputRefs.dateOfBirth}
                    autoFocus={autoFocusFieldName === "dateOfBirth"}
                    onKeyUp={(e) => {
                      setFieldValue("dateOfBirth", e.target.value);
                      mySetPersonalInformation({...myGetPersonalInformation(), ...values});
                    }}
                  />
                  <ErrorMessage
                    name="dateOfBirth"
                    className="field-error-message-container"
                    component={ErrorMessageText}
                  />
                </div>
              )}

              <div className="submit-button-container">
                <button
                  className="action-button mobile-widths"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    Object.keys(errors).length ||
                    isAnyRequiredValueEmpty(values)
                  }
                >
                  Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

CheckoutPersonalInformation.propTypes = {
  goToNextStep: PropTypes.func,
  autoFocusFieldName: PropTypes.string,
  returnStepIndex: PropTypes.number,
  goToStepByIndex: PropTypes.func,
};

CheckoutPersonalInformation.defaultProps = {
  goToNextStep: () => {},
  autoFocusFieldName: "",
  goToStepByIndex: () => {},
};
