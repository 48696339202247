import { useContext } from "react"
import { ConfigContext } from "contexts/ConfigContext"
import ExpandableGroup from "components/ExpandableGroup/ExpandableGroup"
import Footer from "components/Footer/Footer"
import { styles } from "./FAQ.styles"

export default function FAQ() {
  const {
    config: { faq },
  } = useContext(ConfigContext)

  return (
    <div css={styles()}>
      <h1 className="title">Frequently Asked Questions</h1>
      <div className="faq-container">
        <ExpandableGroup items={faq} />
      </div>
      <Footer />
    </div>
  )
}
