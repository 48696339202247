import { useContext, useState } from "react"
import Arrow from "assets/icons/arrow.svg"
import { ConfigContext } from "contexts/ConfigContext"
import { styles } from "./SponsorBadge.styles"

export default function SponsorBadge() {
  const { config } = useContext(ConfigContext)

  const [isSponsorInfoShown, setIsSponsorInfoShown] = useState(false)
  const toggleSponsorInfo = () => setIsSponsorInfoShown(!isSponsorInfoShown)

  return (
    <div css={styles({ isSponsorInfoShown })}>
      <div className="sponsor-image-container">
        <img
          className="sponsor-image"
          src={config.sponsorImageUrl}
          alt="Sponsor"
        />
        <div className="sponsor-image-blur" />
      </div>

      <div className="sponsor-info-container">
        <div className="sponsor-info">
          {config.sponsorLogoUrl && (
            <div className="sponsor-logo-container">
              <img src={config.sponsorLogoUrl} alt="Sponsor logo" />
            </div>
          )}
          {config.sponsorDescription && (
            <p className="sponsor-description">{config.sponsorDescription}</p>
          )}
        </div>
        <div className="sponsor-button-toggle-container">
          <button
            className="sponsor-button-toggle"
            type="button"
            onClick={toggleSponsorInfo}
          >
            <img className="toggle-arrow-icon" src={Arrow} alt="Toggle Arrow" />
            <span className="sponsor-button-toggle-text">About sponsor</span>
            <img className="toggle-arrow-icon" src={Arrow} alt="Toggle Arrow" />
          </button>
        </div>
      </div>
    </div>
  )
}
