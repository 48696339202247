import { useContext, useCallback, useState } from "react"
import React from "react"
import { useHistory } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as yup from "yup"
import axios from "axios"
import Input from "components/Input/Input"
import TextArea from "components/TextArea/TextArea"
import ErrorMessageText from "components/ErrorMessageText/ErrorMessageText"
import Footer from "components/Footer/Footer"
import { ConfigContext } from "contexts/ConfigContext"
import LinkIconWhite from "assets/icons/link-icon-white.svg"
import { styles } from "./Contact.styles"
import { API_BASE_URL, CONTACT_US_API_ROUTE_BASE } from 'config'
import useUrl from "hooks/useUrl"

const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Name should be at least 3 characters long")
    .required("Required")
    .max(30, "Name is too long"),
  email: yup
    .string()
    .email(
      "Wrong email format"
    )
    .required("Required")
    .max(40, "Email is too long"),
  message: yup.string().required("Required").max(500, "Message is too long"),
})

export default function Contact() {
  const { config } = useContext(ConfigContext)

  const [isSubmitting, setIsSubmitting] = useState(null)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(null)

  const history = useHistory()
  const { exactUrl } = useUrl()

  const sendContactUs = useCallback(
    async (values) => {
      const url = `${API_BASE_URL}${CONTACT_US_API_ROUTE_BASE}`
      try {
        const data = {
          vendorReference: config.vendorIdentifier,
          senderName: values.name,
          senderEmail: values.email,
          message: values.message
        };

        setIsSubmitting(true)
        const response = await axios.post(url, data)
        setIsSubmitting(false)
        setIsSubmitSuccess(true)

        setTimeout(() => {
          history.push(`${exactUrl}/contact-us-success`)
        }, 2 * 1000)

        return response
      } catch (e) {
        setIsSubmitSuccess(false);
      }
    }
  )

  const handleSubmit = (values) => {
    sendContactUs(values);
  }

  return (
    <>
      <div css={styles()}>
        <h1 className="head">Get in touch</h1>
        <p className="paragraph">Have a question? Just shoot us a message.</p>
        <div>
          <Formik
            initialValues={{ name: "", email: "", message: "" }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnChange
          >
              <Form>
                <div className="field-container">
                  <Field
                    name="name"
                    type="text"
                    placeholder="Your name"
                    autoComplete="name"
                    component={Input}
                  />
                  <ErrorMessage
                    name="name"
                    className="field-error-message-container"
                    component={ErrorMessageText}
                  />
                </div>
                <div className="field-container">
                  <Field
                    name="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    component={Input}
                  />
                  <ErrorMessage
                    name="email"
                    className="field-error-message-container"
                    component={ErrorMessageText}
                  />
                </div>
                <div className="field-container">
                  <Field
                    name="message"
                    placeholder="Message"
                    autoComplete="off"
                    rows="6"
                    component={TextArea}
                  />
                  <ErrorMessage
                    name="message"
                    className="field-error-message-container"
                    component={ErrorMessageText}
                  />
                </div>
                <button
                  className="regular-button submit-button-container"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <img
                      className="submit-button-animation"
                      src="/animations/processing.gif"
                      alt="Submit in progress animation"
                    />
                  ) : isSubmitSuccess ? (
                    <img
                      className="submit-button-animation"
                      src="/animations/success.gif"
                      alt="Submit success animation"
                    />
                  ) : (
                    <span>Send<img src={LinkIconWhite} alt="Link" /></span>
                  )}
                  
                </button>
              </Form>
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  )
}
