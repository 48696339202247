import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ConfigProvider } from "./contexts/ConfigContext"
import { PurchaseProvider } from "./contexts/PurchaseContext"
import App from "./App"
import "./index.scss"

// import reportWebVitals from './reportWebVitals';

function AppWrapped() {

  return (
    <PurchaseProvider>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </PurchaseProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/event/:eventId">
          <AppWrapped />
        </Route>
        <Route>
          <AppWrapped />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//reportWebVitals();
