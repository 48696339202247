import { css } from "@emotion/react"
import { field, label } from "styles/mixins"

export const styles = ({ error }) => css`
  .text-area {
    ${field({ error })}
    resize: none;
  }

  .label {
    ${label()}
  }
`
