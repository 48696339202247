import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"
import { field, label } from "styles/mixins"

export const styles = ({ error }) => css`
  .input {
    ${field({ error })}

    &[type="date"] {
      min-width: 95%;
      display: block;
      min-height: 48px;
      appearance: textfield;
    }
  }

  .label {
    ${label()}
  }
  ${mediaQueries.mobileScreen}{
    
  }
`
