import { ConfigContext } from "contexts/ConfigContext";
import React, { useContext } from "react";
import { styles } from "./ManageSubscriptions.styles";
import { STATUS, useManageSubscriptions } from "./useManageSubscriptions";
import Select from "react-select";
import { UNSUBSCRIPTION_REASONS } from "./constants";

export default function ManageSubscriptions() {
  const { config } = useContext(ConfigContext);
  const {
    unsubscribe,
    status,
    subscriptions,
    isValidForm,
    showOtherReason,
    handleOtherReasonChange,
    handlePledgeSelection,
    handleReasonSelection,
    returnToHome,
  } = useManageSubscriptions();

  if (status === STATUS.noSubscriptions) {
    return (
      <div css={styles({ config })}>
        <div className="success-message-container">
          <p className="title">This link is no longer active</p>
          <p className="subtitle">
            <b>Return Home</b>
          </p>
          <button
            className="action-button action-button--full-width"
            onClick={returnToHome}
          >
            Return Home
          </button>
        </div>
      </div>
    );
  }

  if (status === STATUS.loadingSubscribtions) {
    return (
      <div css={styles({ config })}>
        <p className="title">Loading...</p>
      </div>
    );
  }

  if (status === STATUS.unsubscribedSuccessfully) {
    return (
      <div css={styles({ config })}>
        <div className="success-message-container">
          <img
            className="success"
            src="/icons/done.png"
            alt="Checkout in progress animation"
          />
          <p className="title">Successfully Unsubscribed</p>
          <p className="subtitle">
            <b>Return Home</b>
          </p>
          <button
            className="action-button action-button--full-width"
            onClick={returnToHome}
          >
            Return Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div css={styles({ config })}>
      <div className="form-container">
        <p className="title">Unsubscribe</p>
        <p className="label">
          1. Choose the pledges you want to unsubscribe from
        </p>
        <div className="checkbox-container">
          {subscriptions?.map((subscription) => {
            return (
              <label className="checkbox">
                <p>{subscription.eventName}</p>
                <input
                  onChange={(e) => handlePledgeSelection(e.target)}
                  id={subscription.subscriptionId}
                  name={subscription.eventName}
                  value={subscription.subscriptionId}
                  type="checkbox"
                />
                <span className="checkmark"></span>
              </label>
            );
          })}
        </div>
        <div className="dropdown-container">
          <p className="label">2. Reason to unsubscribe</p>
          <select
            options={UNSUBSCRIPTION_REASONS}
            placeholder="Reason to unsubscribe"
            onChange={(reason) => handleReasonSelection(reason.target.value)}
            className="dropdown"
          >
            {UNSUBSCRIPTION_REASONS.map((reason) => (
              <option label={reason.label} value={reason.value}>
                {reason.label}
              </option>
            ))}
          </select>
          {showOtherReason && (
            <input
              onChange={(e) => {
                handleOtherReasonChange(e.target.value);
              }}
              className="other-reason-input"
              placeholder="Please specify reason for unsubscribing"
            />
          )}
        </div>
        <div className="submit-button-container">
          <button
            onClick={unsubscribe}
            className="action-button action-button--full-width"
            type="submit"
            disabled={status === STATUS.unsubscribing || !isValidForm}
          >
            {status === STATUS.unsubscribing ? (
              <img
                className="loading-gif"
                src="/animations/processing.gif"
                alt="in progress animation"
              />
            ) : (
              "Unsubscribe"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
