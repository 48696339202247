import { getSubscriptions, updateSubscriptions } from "api/subscriptions"
import { useEffect, useState, useMemo } from "react"
import { useLocation, useHistory } from "react-router-dom"

export const STATUS = {
  idle: "IDLE",
  loadingSubscribtions: "LOADING_SUBSCRIPTIONS",
  unsubscribing: "UNSUBSCRIBING",
  unsubscribedSuccessfully: "UNSUBSCRIBED_SUCCESSFULLY",
  noSubscriptions: "NO_SUBSCRIPTIONS",
  error: "ERROR",
}
export const useManageSubscriptions = () => {
  const [token, setToken] = useState(null)
  const [subscriptions, setSubscriptions] = useState(null)
  const [status, setStatus] = useState(STATUS.loadingSubscribtions)
  // pledges to unsubscribe from
  const [pledges, setPledges] = useState(new Set())
  const [reason, setReason] = useState(null)
  const [otherReason, setOtherReason] = useState(null)

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const history = useHistory()

  useEffect(() => {
    loadAuthToken()
  }, [])

  useEffect(() => {
    loadSubscriptions()
  }, [token])

  const loadAuthToken = async () => {
    const token = params.get("t")
    if (!token) {
      history.push("/get-login-link")
    } else {
      setToken(token)
    }
  }

  const loadSubscriptions = async () => {
    setStatus(STATUS.loadingSubscribtions)
    if (token) {
      try {
        const userSubscriptions = await getSubscriptions({ token })
        setStatus(STATUS.idle)
        setSubscriptions(userSubscriptions)
        if (!userSubscriptions.length) {
          setStatus(STATUS.noSubscriptions)
        }
      } catch (err) {}
    }
  }

  const unsubscribe = async () => {
    setStatus(STATUS.unsubscribing)
    const unsubscriptionReason = reason === "other" ? otherReason : reason
    const subscriptionsStatus = Array.from(pledges).reduce(
      (pledges, newPledge) => [
        ...pledges,
        {
          subscriptionId: newPledge,
          subscriptionStatus: false,
          reason: unsubscriptionReason,
        },
      ],
      []
    )
    await updateSubscriptions({ token, subscriptionsStatus })
    setStatus(STATUS.unsubscribedSuccessfully)
  }

  const handlePledgeSelection = (pledge) => {
    if (pledge.checked) {
      let updatedPledges = new Set(pledges)
      updatedPledges.add(pledge.value)
      setPledges(updatedPledges)
    } else {
      let updatedPledges = new Set(pledges)
      updatedPledges.delete(pledge.value)
      setPledges(updatedPledges)
    }
  }

  const handleReasonSelection = (reason) => {
    setReason(reason)
    if (reason !== "other") {
      setOtherReason(null)
    }
  }

  const handleOtherReasonChange = (reason) => {
    setOtherReason(reason)
  }

  const returnToHome = () => {
    history.push("/")
  }

  const isValidForm = useMemo(() => {
    return (
      pledges.size > 0 &&
      reason !== null &&
      !(reason === "other" && !otherReason && !otherReason?.length)
    )
  }, [pledges, reason, otherReason])

  const noSubscriptions = status === STATUS.idle && !subscriptions?.length
  const showOtherReason = reason === "other"

  return {
    unsubscribe,
    status,
    subscriptions,
    isValidForm,
    handlePledgeSelection,
    noSubscriptions,
    handleReasonSelection,
    showOtherReason,
    handleOtherReasonChange,
    returnToHome,
  }
}
