import { useMemo, useContext } from "react"
import PropTypes from "prop-types"
import BackButton from "components/BackButton/BackButton"
import { ConfigContext } from "contexts/ConfigContext"
import { styles } from "./CheckoutNav.styles"
import { APP_VARIANTS } from "config"
import { PurchaseContext } from "contexts/PurchaseContext"

export default function CheckoutNav({
  steps,
  currentStepIndex,
  backToHomeHandler,
  goToStepByIndex
}) {
  const { config } = useContext(ConfigContext)
  const { myGetTeamIndex } = useContext(PurchaseContext)
  const eventName = config?.teams?.[myGetTeamIndex()]?.pledgePerWin?.event?.name

  const variant = config.variant

  const handleStepChange = (index) => {

    if (index < currentStepIndex) {

      goToStepByIndex({
        stepIndex: index,
        autoFocusFieldName: "firstName",
        returnStepIndex: currentStepIndex,
      })
    }
  }

  const stepComponents = useMemo(
    () =>
      steps.map((step, index) => {
        const isCompletedStep = index < currentStepIndex
        const isCurrentStep = index === currentStepIndex
        return (
          <div
            className={`step ${isCompletedStep
              ? "step--completed"
              : isCurrentStep
                ? "step--current"
                : "step--upcoming"
              }`}
            key={step}
            onClick={() => handleStepChange(index)}
          >
            {step}
          </div>
        )
      }),
    [currentStepIndex, steps]
  )

  return (
    <div css={styles({ config })} className="checkout-nav">
      <div className="checkout-nav-mobile">
        <BackButton onClick={backToHomeHandler} />
        <div className="step-status">
          Step {currentStepIndex + 1} of {steps.length}
        </div>
      </div>
      <div className="checkout-nav-desktop">
        <div className='row'>
          {stepComponents}
        </div>
      </div>
    </div >
  )
}

CheckoutNav.defaultProps = {
  steps: [],
  currentStepIndex: 0,
  backToHomeHandler: () => { },
}

CheckoutNav.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  currentStepIndex: PropTypes.number,
  backToHomeHandler: PropTypes.func,
}
