import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`

  .head {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .field-container {
    margin-bottom: 20px;
  }

  .submit-button-container {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    margin-bottom-30px;
  }

  .mobile-widths {
    width: 100%;
  }

  ${mediaQueries.tabletScreen} {
    .head {
      font-size: 36px;
      line-height: 110%;
      letter-spacing: -1px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    .head {
      margin-bottom: 30px;
    }
    .submit-button-container {
      position: initial;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .mobile-widths{
      width: 100%;
      margin-left: 0;
    }
  }
`
