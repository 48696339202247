import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ config }) => css`
  min-height: calc(100vh - 270px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;

  .head {
    color: ${config.primaryColor};
    margin-bottom: 10px;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
  }

  .paragraph {
    margin-bottom: 40px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .field-container {
    margin-bottom: 10px;
  }

  .response-head {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -2px;
    text-align: center;
    letter-spacing: -1px;
    color: ${config.primaryColor};
    margin-bottom: 20px;
  }

  .response-paragraph {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #111319;
  }

  .error-message {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #f24b4b;
  }

  ${mediaQueries.tabletScreen} {
    min-height: calc(100vh - 300px);
    padding-top: 80px;
    max-width: 560px;

    .head {
      font-size: 56px;
      line-height: 100%;
      letter-spacing: -2px;
    }

    .paragraph {
      font-size: 16px;
      line-height: 140%;
    }

    .form-container form {
      display: flex;
    }

    .field-container {
      flex: 1;
    }

    .submit-button-container {
      width: 158px;
      margin-left: 10px;
    }

    .response-head {
      font-style: normal;
      font-weight: bold;
      font-size: 76px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -2px;
    }

    .response-paragraph {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    min-height: calc(100vh - 270px);
    max-width: 600px;
  }
`
