import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ isMenuOpen, fullWidth }) => css`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 10;

  .logo-container {
    width: 100%;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }

  .logo-image {
    width: 41px;
    margin-right: 10px;
  }

  .logo-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .menu-button-container {
    display: flex;
    align-items: center;
  }

  .menu-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #111319;
    z-index: 100;
    top: ${isMenuOpen ? "0" : "-105%"};
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s ease-in-out;
  }

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu-item {
    text-align: center;
    margin: 60px 0;

    a {
      text-decoration: none;
      color: #ffffff;
      font-weight: 600;
      font-size: 36px;
      line-height: 110%;
      letter-spacing: -1px;
    }
  }

  ${mediaQueries.mobileLandscapeScreen} {
    .menu {
      flex-direction: row;
      display: flex;
      position: relative;
      top: -30px;
    }

    .menu-item {
      margin: 0 30px;
    }
  }

  ${mediaQueries.tabletScreen} {
    padding-left: 40px;
    padding-right: 40px;

    .logo-image {
      width: 60px;
    }

    .logo-text {
      font-size: 20px;
      line-height: 120%;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    align-items: center;
    right: ${fullWidth ? "0" : "256px"};
    padding-left: 30px;
    padding-right: 30px;

    .menu-container {
      position: initial;
      background-color: transparent;
      justify-content: flex-end;
      padding-right: 10px;
    }

    .menu {
      display: flex;
    }

    .menu-item {
      margin: 0 0 0 20px;

      a {
        color: #111319;
        letter-spacing: -0.5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
      }
    }

    .menu-button-container {
      display: none;
    }
  }

  ${mediaQueries.desktopMediumScreen} {
    right: ${fullWidth ? "0" : "360px"};
  }

  ${mediaQueries.desktopLargeScreen} {
    right: ${fullWidth ? "0" : "480px"};
  }
`
