import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ bg, text }) => css`
  width: 100vw;
  height: 100vh;
  background-color: #${bg || "313339"};
  position: relative;

  .head-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 12px 24px;
  }

  .head-text {
    color: #${text || "ffffff"};
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 100%;
    letter-spacing: -2px;
    text-transform: uppercase;
  }

  .head-logo-container {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    display: flex;
  }

  .head-logo {
    width: 100%;
  }

  .pot-size {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #${text || "ffffff"};
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 100%;
    letter-spacing: -3px;
  }

  .pot-announcement {
    position: absolute;
    bottom: 12px;
    left: 24px;
    right: 12px;
    color: #${text || "ffffff"};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.5px;
  }

  .pot-day {
    font-weight: bold;
  }

  ${mediaQueries.mobileLargeScreen} {
    .head-container {
      padding: 14px 14px 14px 28px;
    }

    .head-text {
      font-size: 28px;
    }

    .head-logo-container {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
    }

    .pot-size {
      font-size: 72px;
      letter-spacing: -4px;
    }

    .pot-announcement {
      font-size: 20px;
      left: 28px;
      bottom: 14px;
    }
  }

  ${mediaQueries.tabletScreen} {
    .head-container {
      padding: 16px 16px 16px 32px;
    }

    .head-text {
      font-size: 32px;
    }

    .head-logo-container {
      width: 130px;
      min-width: 130px;
      max-width: 130px;
    }

    .pot-size {
      font-size: 96px;
      letter-spacing: -5px;
    }

    .pot-announcement {
      font-size: 24px;
      left: 32px;
      bottom: 16px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    .head-container {
      padding: 18px 18px 18px 46px;
    }

    .head-text {
      font-size: 40px;
    }

    .head-logo-container {
      width: 140px;
      min-width: 140px;
      max-width: 140px;
    }

    .pot-size {
      font-size: 144px;
      letter-spacing: -6px;
    }

    .pot-announcement {
      font-size: 24px;
      left: 46px;
      bottom: 18px;
    }
  }

  ${mediaQueries.desktopMediumScreen} {
    .head-container {
      padding: 32px 32px 32px 56px;
    }

    .head-text {
      font-size: 64px;
    }

    .head-logo-container {
      width: 140px;
      min-width: 140px;
      max-width: 140px;
    }

    .pot-size {
      font-size: 264px;
      letter-spacing: -7px;
    }

    .pot-announcement {
      font-size: 24px;
      left: 56px;
      bottom: 30px;
    }
  }

  ${mediaQueries.desktopLargeScreen} {
    .head-container {
      padding: 58px 58px 58px 80px;
    }

    .head-text {
      font-size: 76px;
    }

    .head-logo-container {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }

    .pot-size {
      font-size: 324px;
      letter-spacing: -8px;
    }

    .pot-announcement {
      font-size: 24px;
      left: 80px;
      bottom: 58px;
    }
  }
`
