export const API_BASE_URL = "https://api.cueaudio.com/v1";
  // process.env.REACT_APP_MODE === "development"
  //   ? "https://api.dev.cueaudio.com/v1"
  //   : process.env.REACT_APP_MODE === "production"
  //   ? "https://api.cueaudio.com/v1"
  //   : "https://api.dev.cueaudio.com/v1" // local development;
// http://localhost:52528

export const CHECKOUT_API_ROUTES = {
  CREATE_PAYMENT_INTENT:
    process.env.REACT_APP_MODE !== "production"
      ? "/fundraising/cue5050/public/create-payment-intent"
      : "/fundraising/cue5050/public/create-payment-intent",
  FINALIZE_FULFILL:
    process.env.REACT_APP_MODE !== "production"
      ? "/fundraising/cue5050/public/finalize-fulfill"
      : "/fundraising/cue5050/public/finalize-fulfill",
}

export const SUBCRIPTIONS_ROUTES = {
  CREATE_SUBSCRIPTION: "/fundraising/ppw/subscription/create",
  SEND_PUBLIC_LOGIN_LINK: "/fundraising/ppw/site/public/login-link",
  GET_SUBSCRIPTIONS: "/fundraising/ppw/site/public/subscriptions",
  UPDATE_SUBSCRIPTIONS: "/fundraising/ppw/site/public/subscriptions/update",
}

export const CONFIG_URI =
  process.env.REACT_APP_MODE === "local"
    ? process.env.REACT_APP_VARIANT === "cue"
      ? "/examples/cue/default.json"
      : "/examples/ppw/default.json"
    : "/default.json"

export const HISTORY_API_ROUTE_BASE = "/fundraising/cue5050/site/public/history/"
export const CONTACT_US_API_ROUTE_BASE = "/fundraising/cue5050/site/public/contact-us/"
export const AM_I_A_WINNER_BASE = "/fundraising/cue5050/site/public/did-i-win/"
export const WHERE_ARE_MY_TICKETS = "/fundraising/cue5050/site/public/"

export const APP_VARIANTS = {
  CUE: "cue",
  PPW: "ppw",
}

export const AGREEMENT_API_ROUTES = {

  PRIVACY_API_ROUTE: 
    process.env.REACT_APP_MODE === "local" 
      ? process.env.REACT_APP_VARIANT === "cue"
        ? "/examples/cue/privacy.html" 
        : "/examples/ppw/privacy.html"
      : "/privacy.html",

  TOS_API_ROUTE:
    process.env.REACT_APP_MODE === "local"
      ? process.env.REACT_APP_VARIANT === "cue"
        ? "/examples/cue/tos.html"
        : "/examples/ppw/tos.html"
      : "/tos.html"
}

