import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ config }) => css`
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;

  .other-reason-input {
    border-radius: 10px;
    width: 100%;
    outline: none;
    font-size: 10pt;
    background-color: #fbfcff;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #e5e7f0;
    margin: 30px 0;
  }

  .title {
    font-size: 30pt;
    font-weight: 900;
    color: ${config.primaryColor};
    margin-bottom: 35px;
  }

  .subtitle {
    font-size: 13pt;
    margin-bottom: 40px;
  }

  .form-container {
    width: 50%;
    max-width: 550px;
    border: solid 1px #d9d9d9;
    border-radius: 15px;
    padding: 40px;
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    margin-bottom: 30px;

    input {
      margin-right: 10px;
    }

    .checkbox {
      display: block;
      position: relative;
      padding-left: 22px;
      margin-bottom: 25px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 14px;
    }

    .checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 17px;
      width: 17px;
      background-color: #eee;
      border-radius: 5px;
    }

    .checkbox:hover input ~ .checkmark {
      background-color: #ccc;
    }

    .checkbox input:checked ~ .checkmark {
      background-color: ${config.primaryColor};
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .checkbox input:checked ~ .checkmark:after {
      display: block;
    }

    .checkbox .checkmark:after {
      left: 6px;
      top: 3px;
      width: 3px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .checkbox p {
      margin-top: 1px;
    }
  }

  .success-message-container {
    width: 50%;
    max-width: 650px;
    border: solid 1px #d9d9d9;
    border-radius: 15px;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      text-align: center;
    }

    img {
      width: 100px;
      margin-bottom: 30px;
      opacity: 0.15;
    }
  }

  .loading-gif {
    height: 20px;
    width: 95px;
    object-fit: cover;
  }

  .dropdown-container {
    margin-bottom: 10px;

    .css-1s2u09g-control {
      padding-left: 10px;
      background-color: #fbfcff;
      height: 48px;
      border-radius: 10px;
      border: 1px solid #e5e7f0;
    }

    .dropdown {
      width: 100%;
    }
  }
  ${mediaQueries.mobileScreen} {
    .form-container{
      width:100%;
    }

    .title{
      font-size:24px;
      text-align:center;
    }

    .label{
    }

    .success-message-container{
      width:100%;
    }

    .subtitle{
      font-size:14px;
    }

    .action-button {
        font-size:14px;
    }

    .css-14el2xx-placeholder{
      font-size:14px;
    }
  }

  .label {
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: 700;
  }

  ${mediaQueries.tabletScreen} {
    .other-reason-input {
      font-size: 12pt;
    }
  }
`
