import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = () => css`
  width: 100%;

  .table {
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
  }

  .table::-webkit-scrollbar {
    display: none;
  }

  .record {
    display: flex;
    flex-wrap: nowrap;
    padding: 20px 16px;
    background-color: #fbfcff;
    border-radius: 10px;
    border: 1px solid #eceef7;
    margin-top: 4px;
    width:100%;
  }

  .record-cell {
    display: table-cell;
    margin-top: 16px;
    margin-right: 2rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .record-cell-name {
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: #aaacb4;
    white-space: normal;
  }

  .record-cell-value {
    white-space: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: #111319;
  }

  .head {
    display: table-header-group;
  }

  .head-row {
    display: flex;
    flex-wrap: nowrap;
  }

  .head-cell {
    display: table-header;
    white-space: normal;
    margin-right: 2rem;
  }

  .body {
    display: table-row-group;
  }

  ${mediaQueries.mobileLargeScreen} {
    .table {
      width: 100%;
      display: table;
      overflow-x: initial;
      white-space: initial;
      margin: initial;
      max-width: initial;
    }

    .head-row {
      padding: 10px 20px;
      background-color: #fbfcff;
      border-radius: 10px;
      border: 1px solid #eceef7;
      margin-top: 4px;
    }

    .head-cell {
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.5px;
      color: #aaacb4;
      display: block;
      white-space: normal;
      margin-right: 0;
    }

    .record {
      padding: 22px 20px;
    }

    .record-cell {
      margin-top: 0;
      margin-right: 0;
    }

    .record-cell-name {
      white-space: normal;
    }

    .head-row,
    .record {
      display: flex;
      flex-wrap: nowrap;
    }

    .record-cell,
    .head-cell {
      text-align: left;
    }

    .record-cell-value {
      white-space: normal;
    }
  }
`
