import PropTypes from "prop-types"
import { styles } from "./WinningsTable.styles"

export default function WinningsTable({ colNames, colWidths, data }) {
  const noWidthsProvided = !colWidths.length

  const getColWidth = (index) => noWidthsProvided ? `${100 / colNames.length}%` : colWidths[index]

  if (!Array.isArray(colNames)) {
    return <div></div>
  }

  const head = colNames?.map((colName, i) => (
    <th className="head-cell" style={{ width: getColWidth(i) }} key={colName}>
      {colName}
    </th>
  ))

  const records = data.map((record, j) => {
    const cells = record.map((cell, i) => (
      <td key={cell + colNames?.[i]} className="record-cell" style={{ width: getColWidth(i) }}>
        <div className="record-cell-content-container">
          <div className="record-cell-name">{colNames?.[i]}</div>
          <div className="record-cell-value">{cell}</div>
        </div>
      </td>
    ))
    return (
      <tr className="record" key={record[0] + j}>
        {cells}
      </tr>
    )
  })

  return (
    <table css={styles()}>
      <thead className="head">
        <tr className="head-row">{head}</tr>
      </thead>
      <tbody className="body">{records}</tbody>
    </table>
  )
}

WinningsTable.defaultProps = {
  colNames: [],
  colWidths: [],
  data: [],
}

WinningsTable.propTypes = {
  colNames: PropTypes.arrayOf(PropTypes.string),
  colWidths: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
}
