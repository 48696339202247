import { styles } from "./TextArea.styles"

export default function TextArea({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  return (
    <div css={styles({ error: touched[field.name] && errors[field.name] })}>
      {props.label && (
        <label htmlFor={props.id || props.name}>{props.label}</label>
      )}
      <textarea className="text-area" {...field} {...props} />
    </div>
  )
}
