import { useContext } from "react"
import { styles } from "./Home.styles"
import HowItWorksSection from "components/HowItWorksSection/HowItWorksSection"
import RafflePot from "components/RafflePot/RafflePot"
import OrganizationSection from "components/OrganizationSection/OrganizationSection"
import SponsorBadge from "components/SponsorBadge/SponsorBadge"
import RecentWinnings from "components/RecentWinnings/RecentWinnings"
import TopAskedQuestions from "components/TopAskedQuestions/TopAskedQuestions"
import Footer from "components/Footer/Footer"
import { ConfigContext } from "contexts/ConfigContext"
import { APP_VARIANTS } from "config"
import TeamsSection from "components/TeamsSection/TeamsSection"
import RecentEvents from "components/RecentEvents/RecentEvents"

export default function Home() {

  localStorage.clear();

  const { config } = useContext(ConfigContext)

  const { bannerUrl, eventName, active, sponsorImageUrl, recentWinnings, recentGames, faq } =
    config

  if (config.variant === APP_VARIANTS.PPW) {
    return (
      <div css={styles({ config })}>
        <div className="container">
          <div>
            {bannerUrl && (
              <div className="horizontal-main-image-container">
                <img className="main-image" src={bannerUrl} alt="Main" />
              </div>
            )}
            <div className='padded-container'>
              <TeamsSection />
              <OrganizationSection />
              {/* {recentGames && !!recentGames.length && <RecentEvents />} */}
              {!!faq && !!faq.length && <TopAskedQuestions />}
            </div>
            <Footer />
          </div>
        </div>
      </div >
    )
  }

  return (
    <div css={styles({ config })} >
      <div className="title-container">
        <h1 className="title">{eventName}</h1>
      </div>
      {bannerUrl && (
        <div className="main-image-container">
          <img className="main-image" src={bannerUrl} alt="Main" />
        </div>
      )}
      <div className="upper-section-content">
        {!config.noActiveEvent && <RafflePot notStarted={!active} />}
        <HowItWorksSection />
      </div>
      {!config.noActiveEvent && <OrganizationSection />}
      {!config.noActiveEvent && sponsorImageUrl && <SponsorBadge />}
      {!!recentWinnings && !!recentWinnings.length && <RecentWinnings />}
      {!!faq && !!faq.length && <TopAskedQuestions />}
      <Footer />
    </div>
  )
}
