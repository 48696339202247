import React, { useState, useEffect, useCallback } from "react"
import { useParams, useRouteMatch, useHistory } from "react-router-dom"
import axios from "axios"
import { CONFIG_URI, AGREEMENT_API_ROUTES } from "config"
import { EventStatus, structureData } from "utils/helpers"

export const ConfigContext = React.createContext({})

const formConfig = ({ data, eventId }) => {
  const vendorLevelData = {
    logoUrl: data.logoUrl,
    name: data.vendorName,
    bannerUrl: data.bannerUrl,
    recentGames: data.recentGames,
    vendorIdentifier: data.vendorIdentifier,
    primaryColor: data.primaryColor,
    secondaryColor: data.secondaryColor,
    legalUrl: data.legalUrl,
    recentWinnings: data.recentWinnings,
    faq: data.faq,
    publicStripeKey: data.publicStripeKey,
    charityName: data.charityName,
    vendorName: data.vendorName,
    realEndDateTime: data.realEndDateTime,
    realStartDateTime: data.realStartDateTime,
    variant: data.variant,
    teams: data.teams,
    sponsorUrl: data.sponsorUrl,
    advertisementText: data.advertisementText,
    advertisementUrl: data.advertisementUrl,
    advertisementName: data.advertisementName,
    bankStatementPrefix: data.bankStatementPrefix
  }

  const eventLevelData =
    data.currentEvents?.filter(
      ({ eventId: currentEventId }) => currentEventId === eventId
    )[0] || {}

  return {
    ...vendorLevelData,
    ...eventLevelData,
    eventName: eventId ? eventLevelData.eventName : vendorLevelData.name,
    ticketsPurchaseAvailable: !!eventLevelData?.pricingTiers?.length && eventLevelData.status === EventStatus.InProgress,
    noActiveEvent: !eventId,
  }
}

const getFirstActiveEventId = (currentEvents) => {
  const activeEvents = currentEvents?.filter(({ status }) => status === EventStatus.InProgress);
  const activeEventsByDate = activeEvents?.sort((a, b) => new Date(a.realStartDateTime) - new Date(b.realStartDateTime));
  return activeEventsByDate && activeEventsByDate[activeEventsByDate.length-1] && activeEventsByDate[activeEventsByDate.length-1].eventId
}

const getFirstScheduledEventId = (currentEvents) => {
  const scheduledEventsByDate = currentEvents?.sort((a, b) => new Date(a.realStartDateTime) - new Date(b.realStartDateTime));
  return scheduledEventsByDate && scheduledEventsByDate[0] && scheduledEventsByDate[0].eventId
}

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState({})
  const [configResolved, setConfigResolved] = useState(false)
  const [eventNotFound, setEventNotFound] = useState(null)
  const { eventId: paramEventId } = useParams()
  const eventId = +paramEventId
  const { path } = useRouteMatch()
  const history = useHistory()

  const fetchConfig = useCallback(async () => {
    const { data: rawData } = await axios.get(CONFIG_URI)
    const { data: privacyHtml } = await axios.get(AGREEMENT_API_ROUTES.PRIVACY_API_ROUTE)
    const { data: tosHtml } = await axios.get(AGREEMENT_API_ROUTES.TOS_API_ROUTE)

    const data = structureData(rawData)

    const isEventPath = path === "/event/:eventId"
    const isEventMatch = isEventPath
      ? !!data.currentEvents.filter(
        ({ eventId: currentEventId }) => currentEventId === eventId
      ).length
      : true

    setConfig({
      ...formConfig({
        data,
        eventId:
          isEventPath && isEventMatch
            ? eventId
            : getFirstActiveEventId(data.currentEvents) ||
            getFirstScheduledEventId(data.currentEvents) ||
            null,
      }),
      privacyHtml,
      tosHtml,
      fetchConfig,
    })

    if (!isEventMatch) {
      setEventNotFound(true)
    }

    setConfigResolved(true)
  }, [eventId, path])

  useEffect(() => {
    fetchConfig()
  }, [fetchConfig])

  // do not render the app until we resolve config
  if (!configResolved) {
    return null
  }

  if (eventNotFound) {
    setEventNotFound(false)
    history.push("/404")
    return <></>
  }

  return (
    <ConfigContext.Provider value={{ config }}>
      {children}
    </ConfigContext.Provider>
  )
}
