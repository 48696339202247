import { ConfigContext } from 'contexts/ConfigContext'
import React, { useContext } from 'react'
import { styles } from "./GetLoginLink.styles"
import { useSubscriptions, STATUS } from './useLoginLink'
import BackButton from "components/BackButton/BackButton"
import useUrl from "hooks/useUrl"


export default function GetLoginLink() {
    const { config } = useContext(ConfigContext)
    const { email, setEmail, validEmail, submitEmail, status } = useSubscriptions()

    const { exactUrl } = useUrl();

    if (status === STATUS.emailSent) {
        return (
            <div css={styles({ config })}>
                <div className="back-button-container">
                    <BackButton
                    onClick={() => (window.location.href = `${exactUrl}/`)}
                    buttonText={"Back to Home"}
                    color={"#000000"}
                    hoverColor={"#808080"}
                    />
                </div>
                <div className='main-container'>
                    <img
                        className="email-sent-img"
                        src="/icons/mail.png"
                        alt="Checkout in progress animation"
                    />
                    <p className='title'>Check Your Inbox</p>
                    <p className='subtitle'><b>If you have an active subscription</b>, you should receive an email. Follow the instructions accordingly.</p>
                </div>
            </div >
        )
    }

    return (
        <div css={styles({ config })}>
            <div className="back-button-container">
                <BackButton
                onClick={() => (window.location.href = `${exactUrl}/`)}
                buttonText={"Back to Home"}
                color={"#000000"}
                hoverColor={"#808080"}
                />
            </div>
            <div className='main-container'>
                <div>
                    <p className='title'>Manage My Subscriptions</p>
                    <p className='subtitle'>Enter your email below. You will receive an email containing a link that will allow you to make changes to your subscriptions.</p>
                    <div className='form-container'>
                        <input
                            disabled={status === STATUS.sendingEmail}
                            type='text'
                            placeholder='Email'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <a className={validEmail ? '' : 'disabled'} onClick={submitEmail}>
                            {status === STATUS.sendingEmail ?
                                <img
                                    src="/animations/processing.gif"
                                    alt="in progress animation"
                                />
                                :
                                <>Submit</>
                            }
                        </a>
                    </div>
                    {
                        <p className='error'>
                            {
                                status === STATUS.error &&
                                <>Something went wrong while trying to send the email. Please try again</>
                            }
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}
