import { css } from "@emotion/react"
import { mediaQueries } from "styles/config"

export const styles = ({ config }) => css`

  .padded-container{
    padding-right:40px;
    padding-left:40px;
    @media screen and (max-width: 768px) {
      padding-right:0px;
      padding-left:0px;
    }
  }
  .title-container {
    padding: 20px;
    background: ${config.primaryColor};
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    letter-spacing: -1px;
    color: #ffffff;
  }
  .main-image {
    height: auto;
    width: 100%;
    border-radius: 10px;
    max-height: 768px;
    object-fit: cover;
    object-position: center;
  }
  .upper-section-content {
    display: flex;
    flex-direction: column;
  }

  ${mediaQueries.mobileScreen}{
    .title {
      font-size:24px;
    }
    .title-container{
      padding:10px;
    }
  }
  ${mediaQueries.tabletScreen} {
    .title {
      font-weight: bold;
      font-size: 53px;
      line-height: 100%;
      letter-spacing: -2px;
    }
  }
  ${mediaQueries.desktopSmallScreen} {
    .upper-section-content {
      flex-direction: column-reverse;
    }
  }
  
  ${mediaQueries.desktopMediumScreen} {
    .title {
      font-size: 76px;
    }
  }

`