import {
  Fragment,
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from "react"
import { Redirect } from "react-router-dom"
import PurchaseBar from "components/PurchaseBar/PurchaseBar"
import useUrl from "hooks/useUrl"
import { ConfigContext } from "contexts/ConfigContext"
import CheckoutNav from "./CheckoutNav/CheckoutNav"
import CheckoutPersonalInformation from "./CheckoutPersonalInformation/CheckoutPersonalInformation"
import CheckoutPaymentMethod from "./CheckoutPaymentMethod/CheckoutPaymentMethod"
import CheckoutConfirmation from "./CheckoutConfirmation/CheckoutConfirmation"
import { styles } from "./Checkout.styles"
import { APP_VARIANTS } from "config"
import { PurchaseContext } from "contexts/PurchaseContext"

const STEPS = ["Personal Information", "Payment Method", "Confirmation"]

// redirect to home page if no available tickets to purchase
function withAvailableTickets(Component) {
  return ({ ...props }) => {
    const { config } = useContext(ConfigContext)
    const ticketsPurchaseAvailable = config.ticketsPurchaseAvailable

    if (!ticketsPurchaseAvailable && config.variant === APP_VARIANTS.CUE) {
      return <Redirect to="/" />
    }

    return <Component {...props} />
  }
}

function Checkout({ tickets }) {
  const { config } = useContext(ConfigContext);
  const { myGetPricingTierId, myGetTeamIndex } = useContext(PurchaseContext);
  const teamDescription = config?.teams?.[myGetTeamIndex()]?.description;
  const eventName = config?.teams?.[myGetTeamIndex()]?.pledgePerWin?.event?.name;
  const [isPurchaseMenuOpen, setIsPurchaseMenuOpen] = useState(config.variant === APP_VARIANTS.PPW)
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [autoFocusFieldName, setAutoFocusFieldName] = useState(null)
  const [returnStepIndex, setReturnStepIndex] = useState(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const variant = config.variant;

  const ticketsInfo = config.pricingTiers
  const teamsTiers = config?.teams?.[myGetTeamIndex()]?.pledgePerWin?.pledgePerWinPricingTiers


  const selectedTicketPackage = config.variant === APP_VARIANTS.CUE ?
    ticketsInfo?.find(
      ({ cue5050PricingTierId }) => cue5050PricingTierId === myGetPricingTierId()
    )
    : teamsTiers?.find(
      ({ pledgePerWinPricingTierId }) => pledgePerWinPricingTierId === myGetPricingTierId()
    )

  const statistic = config?.teams?.[myGetTeamIndex()]?.pledgePerWin?.statistic

  const ticketInfoText = useMemo(() => {

    return selectedTicketPackage
    ? config.variant === APP_VARIANTS.PPW ? `$${(selectedTicketPackage.price / 100).toFixed(2)} Per ${statistic}` : `${selectedTicketPackage.description} for $${(selectedTicketPackage.price / 100).toFixed(2)}`
    : "Select Pledge Amount"
  }, [myGetPricingTierId()]);

  const { exactUrl } = useUrl()

  const goToStepByIndex = ({
    autoFocusFieldName,
    stepIndex,
    returnStepIndex,
  } = {}) => {
    setAutoFocusFieldName(autoFocusFieldName || null)
    setReturnStepIndex(returnStepIndex || null)
    setCurrentStepIndex(stepIndex)
  }

  const mobileBackHandler = () => {

    if (currentStepIndex === 0) {

      window.location.href = `${exactUrl}/`

    } else {

      goToStepByIndex({stepIndex: currentStepIndex - 1, returnStepIndex: currentStepIndex})
    }
  }

  const amountElement = useMemo(() => {

    const onTicketReplaceClick = () => {
      setIsPurchaseMenuOpen(!isPurchaseMenuOpen)
    }

    return (
      <div>
        <button
          type="button"
          className="ticket-selected-info ticket-selected-info-mobile"
          onClick={onTicketReplaceClick}
        >
          <span className="ticket-selected-name">{ticketInfoText}</span>
          <span className="ticket-replace">Replace</span>
        </button>
        <div
          className="ticket-selected-info ticket-selected-info-desktop"
        >
          <span className="ticket-selected-name">{ticketInfoText}</span>
        </div>
      </div>
    )
  }, [myGetPricingTierId()])

  const handleWindowSizeChange = () => {

    setScreenWidth(window.innerWidth);
  }

  useEffect(() => {

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const steps = useMemo(() => {
    const goToNextStep = () => setCurrentStepIndex(currentStepIndex + 1)

    return (
      <Fragment>
        <div style={{ display: currentStepIndex === 0 ? "block" : "none" }}>
          <CheckoutPersonalInformation
            ticketText={amountElement}
            goToNextStep={goToNextStep}
            autoFocusFieldName={screenWidth > 768 ? autoFocusFieldName : null}
            returnStepIndex={returnStepIndex}
            goToStepByIndex={goToStepByIndex}
            togglePurchaseMenu={() => {
              setIsPurchaseMenuOpen(!isPurchaseMenuOpen)
            }}
          />
        </div>
        <div style={{ display: currentStepIndex === 1 ? "block" : "none" }}>
          <CheckoutPaymentMethod
            ticketText={amountElement}
            shown={currentStepIndex === 1}
            goToNextStep={goToNextStep}
            autoFocusFieldName={screenWidth > 768 ? autoFocusFieldName : null}
            returnStepIndex={returnStepIndex}
            goToStepByIndex={goToStepByIndex}
            togglePurchaseMenu={() => {
              setIsPurchaseMenuOpen(!isPurchaseMenuOpen)
            }}
          />
        </div>
        <div style={{ display: currentStepIndex === 2 ? "block" : "none" }}>
          <CheckoutConfirmation
            ticketText={amountElement}
            shown={currentStepIndex === 2}
            togglePurchaseMenu={() =>
              setIsPurchaseMenuOpen(!isPurchaseMenuOpen)
            }
            goToStepByIndex={goToStepByIndex}
          />
        </div>
      </Fragment>
    )
  }, [
    currentStepIndex,
    autoFocusFieldName,
    returnStepIndex,
    isPurchaseMenuOpen,
    myGetPricingTierId()
  ])

  const warnFormLeaving = useCallback((e) => {
    const confirmationMessage =
      "All filled data will be lost. Are you sure to leave the page?"
    const event = e || window.event
    event.returnValue = confirmationMessage
    return confirmationMessage
  }, [])

  useEffect(() => {
    window.addEventListener("beforeunload", warnFormLeaving)
    return () => {
      window.removeEventListener("beforeunload", warnFormLeaving)
    }
  }, [warnFormLeaving])

  return (
    <div css={styles({config})} className="checkout">
      <div className="purchase-bar">
        <PurchaseBar
          tickets={tickets}
          isMenuOpen={isPurchaseMenuOpen}
          toggleMenu={() => setIsPurchaseMenuOpen(!isPurchaseMenuOpen)}
          backButtonHandler={() => (window.location.href = `${exactUrl}/`)}
          backButtonText="Back to home"
          align="left"
          alterCheckout
        />
      </div>
      <div className="checkout-page">
        <CheckoutNav
          steps={STEPS}
          currentStepIndex={currentStepIndex}
          backToHomeHandler={mobileBackHandler}
          goToStepByIndex={goToStepByIndex}
        />
        <div className="checkout-step-container">
          {variant === APP_VARIANTS.PPW && eventName && currentStepIndex === 0 && 
            <div className="title-container">
              <div>
                  <h1 className="title">{eventName}</h1>
              </div>
              <hr style={{ borderStyle: "solid" }}></hr>
            </div>
          }
          {variant === APP_VARIANTS.PPW && teamDescription && currentStepIndex === 0 && 
            <div className="description-container">
                <h1 className="description">{teamDescription}</h1>
            </div>
          }
          <div className="checkout-step-content">{steps}</div>
        </div>
      </div>
    </div>
  )
}

export default withAvailableTickets(Checkout)
