import { css } from "@emotion/react"
import { mediaQueries, outerPadding } from "styles/config"

export const styles = ({ ticketsPurchaseAvailable }) => css`
  .page {
    padding: 0 ${outerPadding.mobile} ${outerPadding.mobile};
    margin-top: 70px;
    @media (max-width: 768px) {
      padding: 0 10px 10px;
    }
  }
   
  ${mediaQueries.tabletScreen} {
    .page {
      padding: 0 ${outerPadding.tablet} ${outerPadding.tablet};
      margin-top: 84px;
    }
  }

  ${mediaQueries.desktopSmallScreen} {
    .page {
      padding: 0 ${outerPadding.desktop} ${outerPadding.desktop};
      margin-right: ${ticketsPurchaseAvailable ? 256 : 0}px;
    }
    .checkout-page {
      margin-left: 256px;
    }
  }

  ${mediaQueries.desktopMediumScreen} {
    .page {
      margin-right: ${ticketsPurchaseAvailable ? 360 : 0}px;
    }
    .checkout-page {
      margin-left: 360px;
    }
  }

  ${mediaQueries.desktopLargeScreen} {
    .page {
      margin-right: ${ticketsPurchaseAvailable ? 480 : 0}px;
    }
    .checkout-page {
      margin-left: 480px;
    }
  }
`
