import { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useMemo } from "react"
import { useElements } from "@stripe/react-stripe-js"
import ErrorMessageText from "components/ErrorMessageText/ErrorMessageText"
import { styles } from "./CardInput.styles"

export default function CardInput({
  Component,
  id,
  placeholder,
  onChange,
  cardInputInfo,
  errorMessages,
  textAlign,
  autoFocus,
}) {
  const [isFocused, setIsFocus] = useState(null)

  const stripeElements = useElements()

  const isError = !!cardInputInfo?.error || !!cardInputInfo?.empty

  const cardOptions = useMemo(
    () => ({
      style: {
        base: {
          color: "#111319",
          fontSize: "16px",
          fontFamily:
            '"Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          fontWeight: 400,
          textAlign,

          "::placeholder": {
            fontSize: "16px",
            fontFamily:
              '"Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            fontWeight: 400,
            textAlign,
          },
        },
        invalid: {
          color: "#111319",
        },
      },
      placeholder,
    }),
    [placeholder, textAlign]
  )

  const onReady = (el) => {
    el.addEventListener("focus", () => setIsFocus(true))
    el.addEventListener("blur", () => setIsFocus(false))
  }

  useEffect(() => {
    if (autoFocus) {
      const element = stripeElements.getElement(Component)
      element.focus()
    }
  }, [autoFocus, stripeElements, Component])

  return (
    <Fragment>
      <div css={styles({ isFocused, isError })} tabIndex="-1">
        <Component
          id={id}
          onChange={onChange}
          onReady={onReady}
          options={cardOptions}
        />
      </div>
      {isError && (
        <ErrorMessageText className="field-error-message-container">
          {cardInputInfo?.error?.message ||
            (!!cardInputInfo?.error
              ? errorMessages.default
              : errorMessages.required)}
        </ErrorMessageText>
      )}
    </Fragment>
  )
}

CardInput.defaultProps = {
  placeholder: "",
  onChange: () => { },
  cardInputInfo: {},
  textAlign: "",
  autoFocus: false,
  errorMessages: {
    default: "Card Input is invalid",
    required: "Card Input is required",
  },
}

CardInput.propTypes = {
  id: PropTypes.string.isRequired,
  cardInputInfo: PropTypes.object,
  placeholder: PropTypes.string,
  textAlign: PropTypes.string,
  autoFocus: PropTypes.bool,
  Component: PropTypes.func,
  errorMessages: PropTypes.shape({
    default: PropTypes.string,
    required: PropTypes.string,
  }),
}
