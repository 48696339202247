import PropTypes from "prop-types"
import { Global as EmotionGlobal, css } from "@emotion/react"

export default function Global({ config }) {
  const styles = css`
    .regular-button {
      text-decoration: none;
      border: none;
      color: white;
      padding: 13px 24px;
      text-align: center;
      background-color: ${config.primaryColor};
      border-radius: 10px;
      font-weight: 600;
      font-size: 18px;
      line-height: 130%;
      letter-spacing: -0.5px;
      display: inline-flex;
      width: 100%;
      height: 50px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &:focus {
        outline: none;
        box-shadow: 0px 0px 1px 2px #0d64d8;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      img {
        margin-left: 12px;
      }
    }
  `

  return <EmotionGlobal styles={styles} />
}

Global.defaultProps = {
  config: {},
}

Global.propTypes = {
  config: PropTypes.object,
}
